import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Chip, IconButton, ListItemButton, Menu, Tooltip, Typography } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ReactComponent as Close } from '../../../static/icons/done.svg';
import { ReactComponent as Edit } from '../../../static/icons/edit.svg';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import OZCheckbox from "../../../components/OZCheckbox/OZCheckbox";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import HolidaysForm from "./HolidaysForm";

import './holidays.css';
import { deleteHolidayById, getHolidayById, getHolidaysList, updateHolidayById } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

export default function Holidays() {

  const [loading, setLoading] = useState(false);
  const [holidaysTableData, setHolidaysTableData] = useState([]);
  const [holidayTableDataRef, setHolidayTableDataRef] = useState(null);
  const [holidayFormStatus, setHolidayFormStatus] = useState(false);
  const [onRowClickData, setOnRowClickData] = useState({});

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "0.75rem",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': { borderRadius: "4px", },
    }), []);

  useEffect(() => {
    getHolidays();
  }, []);

  const CampaignsCellRenderer = (holidayRowData) => {

    let chipWidth = 160;
    let totalWidthNeededForChips = chipWidth * holidayRowData.value.length;
    let columnWidth = 500;
    let maxChips = Math.floor(columnWidth / chipWidth);
    if (totalWidthNeededForChips > columnWidth) {
      maxChips = Math.floor(columnWidth / chipWidth) - 1;
    }

    const isEvenRow = holidayRowData.rowIndex % 2 === 0;
    const handleDelete = async (data) => {
      try {
        const updatedData = { ...holidayRowData.data, campaign: holidayRowData.value.filter((campaign) => campaign.campaignId !== data.campaignId) };
        if (updatedData.campaign.length === 0) {
          showErrorNotification(`At least one campaign is required for holiday ${holidayRowData.data.holidayName}`);
          return;
        }
        const resp = await updateHolidayById(updatedData);
        if (resp && resp.Status === "Success") {
          showSuccessNotification(`Campaign ${data.campaignName} removed from holiday ${holidayRowData.data.holidayName}`);
          getHolidays();
        } else {
          showErrorNotification(resp.Message || `Failed to remove campaign ${data.campaignName} from holiday ${holidayRowData.data.holidayName}`);
        }
      }
      catch (e) {
        console.log(e);
        showErrorNotification(`Failed to remove campaign ${data.campaignName} from holiday ${holidayRowData.data.holidayName}`);
      }
    };

    return (
      <Box >
        {holidayRowData.value.map((campaign, index) => (
          (index < maxChips) ?
            <Tooltip title={campaign.campaignName} key={index}>
              <Chip sx={{
                background: isEvenRow ? '#F6F8F9' : '#ffffff',
                color: "#546881"
              }}
                onDelete={() => handleDelete(campaign)}
                deleteIcon={<Close />}
                className="oz-admin-holidays-campaign-chip" key={index} label={campaign.campaignName} style={{ margin: '2px' }} />
            </Tooltip>
            : null
        ))}
        {holidayRowData.value.length > maxChips ?
          <Tooltip
            title={
              <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                {holidayRowData.value.slice(maxChips).map((campaign) => campaign.campaignName).join(', ')}
              </div>
            }
          >
            <Chip
              sx={{
                background: isEvenRow ? '#F6F8F9' : '#ffffff',
                color: "#008DFF"
              }}
              className="oz-admin-holidays-campaign-chip"
              label={`+${holidayRowData.value.length - maxChips}`}
              style={{ margin: '2px', cursor: "pointer" }} />
          </Tooltip>
          :
          null}
      </Box>
    )
  }

  const triggerEdit = async (rowData) => {
    setLoading(true);
    if (rowData.data && rowData.data.id) {
      try {
        const resp = await getHolidayById(rowData.data.id);
        if (resp && resp.Data) {
          setHolidayFormStatus(ADMIN_FORM_MODES.edit);
          let holidayData = resp.Data[0];
          holidayData.campaign = holidayData.campaign.reduce((acc, item) => ([...acc, { id: item.campaignId, campaignName: item.campaignName }]), []);
          setOnRowClickData(holidayData);
        }
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }

  const onClickDelete = async (data) => {
    if (data.id) {
      setLoading(true);
      try {
        const resp = await deleteHolidayById(data.id);
        if (resp && resp.Status === "Success") {
          showSuccessNotification(`Holiday ${data.holidayName} deleted successfully`)
          getHolidays();
        } else {
          showErrorNotification(resp.Message || `Failed to delete holiday ${data.holidayName}`)
        }
        getHolidays();
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        showErrorNotification(`Failed to delete holiday ${data.holidayName}`)
        setLoading(false);
      }
    }
  };

  const AnnuallyRecurringCellRenderer = (holidayRowData) => {
    const [holidayAnchorE1, setHolidayAnchorE1] = useState(false);
    const anchorRef = useRef();
    const { value, data } = holidayRowData;

    const handleChangeIsRecurring = async ({ target: { checked } }) => {
      setLoading(true);
      try {
        const resp = await updateHolidayById({ ...data, recurring: checked });
        if (resp && resp.Status === "Success") {
          showSuccessNotification(`Holiday ${data.holidayName} is ${checked ? 'set to' : 'unset from'} annually recurring`);
          await getHolidays();
        } else {
          showErrorNotification(resp.Message || `Failed to set holiday ${data.holidayName} ${checked ? 'to' : 'from'} annually recurring`);
        }
        setLoading(false);
      }
      catch (e) {
        console.log(e);
        showErrorNotification(`Failed to set holiday ${data.holidayName} ${checked ? 'to' : 'from'} annually recurring`);
        setLoading(false);
      }
    }

    const handleClickEdit = () => {
      triggerEdit({ data: data });
    }

    const onClickMoreVertIcon = () => {
      setTimeout(() => setHolidayAnchorE1(anchorRef?.current), 1);
    };

    const closeHolidayMoreVertMenu = () => {
      setHolidayAnchorE1(false);
    };
    return (
      <Box className="oz-admin-holidays-annually-recurring">
        <Box className="oz-admin-holidays-checkbox">
          <OZCheckbox id={data.id} checked={value} onChange={handleChangeIsRecurring} disabled={!((newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Edit')))} />
        </Box>
        <Box className="oz-admin-holidays-annually-recurring-content">
          <>
            {(newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Edit')) &&
              <IconButton className="oz-admin-holidays-edit-icon" onClick={handleClickEdit}>
                <Edit />
              </IconButton>
            }
            {(newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full')) &&
              <img className="delMoreIcon"
                onClick={onClickMoreVertIcon}
                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
              >
              </img>
            }
          </>
          <div ref={anchorRef}>
            <Menu anchorEl={holidayAnchorE1} open={Boolean(holidayAnchorE1)} onClose={closeHolidayMoreVertMenu}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#FFFFFF",
                  width: "80px",
                  height: "42px",
                },
                "& .MuiMenu-paper": {
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                  border: "0.0625rem solid #E6E5E6",
                  borderRadius: "12px",
                  marginLeft: "-1.0625rem",
                  marginTop: "1.2rem",
                },
              }}>
              <ListItemButton
                onClick={() => onClickDelete(data)}
                sx={{ ...styleListItemButton, marginTop: '3px' }}>
                Delete
              </ListItemButton>
            </Menu>
          </div>
        </Box>
      </Box>
    )

  }


  const GetNameRenderer = (props) => {
    return (
      // <Tooltip title={props?.value ? props?.value : ""}>
      <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
      // </Tooltip>
    )
  }

  const columnDefs = useMemo(() => (
    [
      {
        field: 'holidayName',
        headerName: 'Holiday Name',
        // maxWidth: 235,
        cellRenderer: GetNameRenderer,
      },
      {
        field: 'holidayDate',
        headerName: 'Date',
        maxWidth: 280,
      },
      {
        field: 'campaign',
        minWidth: 500,
        // maxWidth: 500,
        headerName: 'Campaigns',
        cellRenderer: CampaignsCellRenderer,
      },
      {
        field: 'recurring',
        headerName: 'Annually Recurring',
        maxWidth: 10000,
        cellRenderer: AnnuallyRecurringCellRenderer,
      },
    ]
  ), [])

  async function getHolidays() {
    setLoading(true);
    try {
      const resp = await getHolidaysList();
      console.log("holiday List", resp);
      if (resp && resp.Data) {
        setHolidaysTableData(resp.Data)
      }
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const handleChangeFormStatus = (status) => {
    if (status === false) {
      setOnRowClickData({});
    }
    setHolidayFormStatus(ADMIN_FORM_MODES.add);
  }

  const getHolidaysDownload = async () => {
    if (holidaysTableData && holidaysTableData.length && holidaysTableData.length > 0) {
      return holidaysTableData.map(data => {
        return {
          holidayName: data?.holidayName || '',
          holidayDate: data?.holidayDate || '',
          campaign: data?.campaign?.length > 0 ? data.campaign.map(campaign => campaign.campaignName).join(', ') : '',
          recurring: Boolean(data?.recurring) ? 'True' : 'False',
        };
      });;
    }
    return [];
  }

  return (
    <Box className="oz-admin-holidays-container">
      <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
        Configurations
      </Typography>

      <Box className="oz-admin-holidays-header">
        <Typography fontSize={"24px"} fontWeight={600} color={"#212121"} flexGrow={1}>
          Holidays
        </Typography>
        {(newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full')) && (
          <Button variant="contained" className="oz-admin-holidays-add-button" startIcon={<AddBoxOutlinedIcon />} onClick={handleChangeFormStatus}>
            Create Holiday
          </Button>
        )}
      </Box>

      <OZGridToolbar
        title='List of Holidays'
        searchPlaceholder='Agent ID'
        rowCount={holidaysTableData.length}
        dataGridRef={holidayTableDataRef}
        fileName='holidayList'
        downloadBlockNumber={holidaysTableData?.length > 0}
        downloadBlockNumberFunc={getHolidaysDownload}
      />

      {loading ?
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
          <LoadingScreen />
        </div>
        : <OZDataGrid height="26.3rem" setGridRef={setHolidayTableDataRef} columns={columnDefs} data={holidaysTableData} rowClickCallback={triggerEdit} />
      }

      {(holidayFormStatus === ADMIN_FORM_MODES.add || holidayFormStatus === ADMIN_FORM_MODES.edit) && <HolidaysForm refreshRows={getHolidays} changeHolidayFormStatus={setHolidayFormStatus} holidayFormStatus={holidayFormStatus} holidayEditData={onRowClickData} editAccess={(newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Edit'))} />}

    </Box>
  )
}
