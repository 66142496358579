import React, { useState, useMemo } from 'react';
import { Menu, TextField, Typography, Divider, List, ListItem, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import './multiSelect.css'; // Your custom styles

const SkillComponentMultiSelect = ({
  loggedInAgentsByGroup,
  setSelectedAgents,
  selectedAgents = { to: [] },
  setOptions,
  options = [],
  searchOptions,
  handleSearch,
  searchTerm: externalSearchTerm,
  mode
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [internalSearchTerm, setInternalSearchTerm] = useState('');
  const open = Boolean(anchorEl);

  // Internal filtering function
  const filteredOptions = useMemo(() => {
    if (!options || !Array.isArray(options) || options.length === 0) return [];
    if (!internalSearchTerm || internalSearchTerm.trim() === '') return options;

    const searchTermLower = internalSearchTerm.toLowerCase();

    // Filter with proper null checking
    return options
      .map(group => {
        if (!group || !group.items || !Array.isArray(group.items)) {
          return null;
        }

        // Filter items within each group
        const filteredItems = group.items.filter(item =>
          item && item.label &&
          typeof item.label === 'string' &&
          item.label.toLowerCase().includes(searchTermLower)
        );

        // Only include groups that have matching items
        if (filteredItems.length > 0) {
          return { ...group, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean); // Remove empty groups
  }, [options, internalSearchTerm]);

  // Determine which options to display
  const displayOptions = filteredOptions;

  // Handle opening the dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the dropdown
  const handleClose = () => {
    setAnchorEl(null);
    // Clear internal search when closing
    setInternalSearchTerm('');
  };

  // Handle search input changes
  const handleSearchChange = (event) => {
    setInternalSearchTerm(event.target.value || '');
  };

  // Clear search
  const clearSearch = () => {
    setInternalSearchTerm('');
  };

  // Toggle a single item
  const toggleItem = (groupId, itemId) => {
    if (!options || !Array.isArray(options) || !setOptions) return;

    try {
      const updatedOptions = options.map(group => ({
        ...group,
        items: Array.isArray(group.items) ?
          group.items.map(item =>
            item && item.id === itemId ? { ...item, checked: !item.checked } : item
          ) : []
      }));

      setOptions(updatedOptions);
    } catch (error) {
      console.error("Error toggling item:", error);
    }
  };

  // Toggle an entire group (or everyone)
  const toggleGroup = (groupName) => {
    if (!options || !Array.isArray(options) || !setOptions) return;

    try {
      if (groupName === "Everyone") {
        // Check if all items are checked
        const allChecked = options.every(group =>
          Array.isArray(group.items) && group.items.every(item => item && item.checked)
        );

        // Toggle all items
        const updatedOptions = options.map(group => ({
          ...group,
          items: Array.isArray(group.items) ?
            group.items.map(item => item ? { ...item, checked: !allChecked } : item) : []
        }));

        setOptions(updatedOptions);
      } else {
        // Toggle specific group
        const updatedOptions = options.map(group => {
          if (group && group.groupName === groupName) {
            const allChecked = Array.isArray(group.items) &&
              group.items.every(item => item && item.checked);

            return {
              ...group,
              items: Array.isArray(group.items) ?
                group.items.map(item => item ? { ...item, checked: !allChecked } : item) : []
            };
          }
          return group;
        });

        setOptions(updatedOptions);
      }
    } catch (error) {
      console.error("Error toggling group:", error);
    }
  };

  // Check if everyone is selected
  const isEveryOneSelected = useMemo(() => {
    if (!options || !Array.isArray(options) || options.length === 0) return false;

    return options.every(group =>
      group && Array.isArray(group.items) &&
      group.items.length > 0 &&
      group.items.every(item => item && item.checked)
    );
  }, [options]);

  // Render selected agents in the main input
  const renderSelectedAgents = () => {
    if (isEveryOneSelected) {
      return (
        <div style={{ display: "flex", alignItems: "center", marginRight: '8px' }}>
          <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={500}>Everyone</Typography>
        </div>
      );
    } else if (selectedAgents?.to && Array.isArray(selectedAgents.to) && selectedAgents.to.length > 0) {
      return (
        <div
          className="small-scroll"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            width: mode === "Create" ? '850px' : '470px',
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginRight: "8px",
          }}
        >
          <Typography
            color={"#008DFF"}
            fontSize={"14px"}
            fontWeight={500}
          >
            {selectedAgents.to[0]}
          </Typography>

          {selectedAgents.to.length > 1 && (
            <>
              <Typography
                color={"#008DFF"}
                fontSize={"12px"}
                fontWeight={400}
              >
                ..
              </Typography>
              <div
                style={{
                  backgroundColor: "#008DFF",
                  borderRadius: "12px",
                  padding: "0px 8px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                +{selectedAgents.to.length - 1}
              </div>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <TextField
        fullWidth
        onClick={handleClick}
        placeholder={isEveryOneSelected ? "Everyone" : (selectedAgents?.to?.length > 0 ? "" : "Search")}
        value={""}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#ffffff',
            transition: 'all 0.2s',
            '&:hover': {
              borderColor: '#008DFF',
            },
            '&.Mui-focused': {
              borderColor: '#008DFF',
            }
          }
        }}
        InputProps={{
          startAdornment: isEveryOneSelected ? (
            <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={500}>Everyone</Typography>
          ) : (selectedAgents?.to && selectedAgents.to.length > 0) && renderSelectedAgents(),
          // endAdornment: (
          //   <InputAdornment position="end">
          //     <SearchIcon fontSize="small" style={{ color: '#757575' }} />
          //   </InputAdornment>
          // )
        }}
      />

      {/* Menu for multi-select dropdown */}
      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 3,
            style: {
              maxHeight: 'none', // Remove height limit from paper
              width: '280px',
              borderRadius: '8px',
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.15)',
              overflow: 'visible' // Allow content to determine scroll
            }
          }
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <div className="multi-select-container" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          {/* Search field within the dropdown */}
          <div style={{
            padding: '12px',
            background: '#f5f7fa',
            borderBottom: '1px solid #e0e0e0'
          }}>
            <TextField
              fullWidth
              placeholder="Search"
              value={internalSearchTerm}
              onChange={handleSearchChange}
              size="small"
              autoFocus
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#ffffff',
                  borderRadius: '6px',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#008DFF',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#008DFF',
                    borderWidth: '1px',
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" style={{ color: '#757575' }} />
                  </InputAdornment>
                ),
                endAdornment: internalSearchTerm ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={clearSearch}
                      edge="end"
                      size="small"
                      style={{ color: '#757575' }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </div>

          {!internalSearchTerm && (
            <div
              className="multi-select-custom-checkbox"
              style={{
                padding: '12px',
                backgroundColor: '#f5f7fa',
                borderBottom: '1px solid #e0e0e0',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <input
                id="everyone"
                type="checkbox"
                onChange={() => toggleGroup("Everyone")}
                checked={isEveryOneSelected}
                style={{ cursor: 'pointer', width: '16px', height: '16px' }}
              />
              <label
                htmlFor="everyone"
                style={{
                  fontSize: '14px',
                  color: '#212121',
                  fontWeight: 600,
                  marginLeft: '10px',
                  cursor: 'pointer',
                  userSelect: 'none'
                }}
              >
                Everyone
              </label>
            </div>
          )}

          {/* Show message when no search results */}
          {displayOptions.length === 0 && (
            <div style={{
              padding: '24px 16px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px'
            }}>
              <SearchIcon style={{ fontSize: 32, color: '#bdbdbd' }} />
              <Typography variant="body2" color="textSecondary" style={{ fontWeight: 500 }}>
                No matching agents found
              </Typography>
              <Typography variant="caption" color="textSecondary" style={{ fontSize: '12px' }}>
                Try a different search term
              </Typography>
            </div>
          )}

          {/* List of Groups with Agents */}
          <div style={{ padding: '4px 0', maxHeight: 'calc(50vh - 120px)', overflowY: 'auto' }}>
            {displayOptions?.map((group, groupIndex) => (
              <List key={group?.groupName || `group-${groupIndex}`} disablePadding>
                <ListItem
                  sx={{
                    paddingY: '8px',
                    paddingX: '12px',
                    borderLeft: '3px solid transparent',
                    '&:hover': {
                      backgroundColor: '#f5f7fa',
                      borderLeft: '3px solid #008DFF'
                    },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type="checkbox"
                    id={group?.groupName || `group-${groupIndex}`}
                    onChange={() => toggleGroup(group?.groupName)}
                    checked={group?.items?.every(item => item?.checked)}
                    style={{
                      cursor: 'pointer',
                      width: '16px',
                      height: '16px'
                    }}
                  />
                  <label
                    htmlFor={group?.groupName || `group-${groupIndex}`}
                    style={{
                      fontSize: '14px',
                      color: '#212121',
                      fontWeight: 600,
                      marginLeft: '10px',
                      cursor: 'pointer',
                      userSelect: 'none',
                      flex: 1
                    }}
                  >
                    {group?.groupName || `Group ${groupIndex + 1}`}
                  </label>
                </ListItem>

                {group?.items?.map((item, itemIndex) => (
                  <ListItem
                    key={item?.id || `item-${itemIndex}`}
                    sx={{
                      paddingY: '6px',
                      paddingX: '12px',
                      paddingLeft: '28px',
                      '&:hover': {
                        backgroundColor: '#f5f7fa'
                      },
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <input
                      id={(item?.id || `item-${itemIndex}`) + (group?.groupName || `group-${groupIndex}`)}
                      type="checkbox"
                      checked={item?.checked || false}
                      onChange={() => toggleItem(group?.groupName, item?.id)}
                      style={{
                        cursor: 'pointer',
                        width: '14px',
                        height: '14px'
                      }}
                    />
                    <label
                      htmlFor={(item?.id || `item-${itemIndex}`) + (group?.groupName || `group-${groupIndex}`)}
                      style={{
                        fontSize: '13px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: '#424242',
                        flex: 1
                      }}
                    >
                      {item?.label || `Item ${itemIndex + 1}`}
                    </label>
                  </ListItem>
                ))}

                {group?.items?.length > 0 && groupIndex < displayOptions.length - 1 &&
                  <Divider style={{ margin: '4px 0', backgroundColor: '#e0e0e0' }} />
                }
              </List>
            ))}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default SkillComponentMultiSelect;