import React, { useState } from 'react';
import { Autocomplete, TextField, Grid, Box, Typography } from '@mui/material';
import { connect, getIn } from 'formik';
import { DropDownIcon } from '../../../../pages/admin/BulkUpdateSettings/AgentSelector';
import CustomizedCheckbox from '../CustomCheckBox';

const GroupedAutoComplete = connect(({
    formik,
    name,
    label,
    options,
    groupBy,
    disabled,
    placeholder,
    showselectall
}) => {
    const value = getIn(formik.values, name) || [];
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);
    const helperText = touch && error;

    // Add state for input value to maintain search across selections
    const [inputValue, setInputValue] = useState('');

    // Group options by their group property
    const groupedOptions = options.reduce((groups, option) => {
        const group = option[groupBy];
        if (!groups[group]) {
            groups[group] = [];
        }
        groups[group].push(option);
        return groups;
    }, {});

    // Check if all options in a group are selected
    const isGroupSelected = (groupName) => {
        const groupOptions = groupedOptions[groupName];
        return groupOptions.every(option =>
            value.some(selectedOption => selectedOption.id === option.id)
        );
    };

    // Handle select all for a group
    const handleGroupSelectAll = (groupName, checked) => {
        const groupOptions = groupedOptions[groupName];
        let newValue;

        // Filter groupOptions by current search term if input exists
        const filteredGroupOptions = inputValue
            ? groupOptions.filter(option =>
                option.name.toLowerCase().includes(inputValue.toLowerCase()))
            : groupOptions;

        if (checked) {
            // Add all group options that aren't already selected
            newValue = [...value];
            filteredGroupOptions.forEach(option => {
                if (!value.some(selectedOption => selectedOption.id === option.id)) {
                    newValue.push(option);
                }
            });
        } else {
            // Remove all group options
            newValue = value.filter(option =>
                !filteredGroupOptions.some(groupOption => groupOption.id === option.id)
            );
        }
        formik.setFieldValue(name, newValue);
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} mb={1}>
            {label && <label htmlFor={name}
                style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    marginBottom: '6px',
                    marginLeft: '1px',
                    color: disabled ? "#99A0A8" : "#212121"
                }}
            >
                {label}
            </label>}
            <Autocomplete
                componentsProps={{ paper: { sx: { border: "1px solid #D6D6D7", marginTop: "4px", borderRadius: "8px" } } }}
                name={name}
                popupIcon={<DropDownIcon />}
                placeholder={placeholder}
                size='small'
                multiple
                disabled={disabled}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={options}
                value={value}
                limitTags={2}
                groupBy={option => option[groupBy]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}

                // Set and maintain input value
                inputValue={inputValue}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'input') {
                        setInputValue(newInputValue);
                    }
                }}

                // Don't select text on focus
                selectOnFocus={false}

                onChange={(event, newValue) => {
                    formik.setFieldValue(name, newValue);
                }}

                // Custom filter to respect current search
                filterOptions={(options, state) => {
                    // If there's a search term, keep it available for group filtering
                    if (state.inputValue) {
                        return options.filter(option =>
                            option.name.toLowerCase().includes(state.inputValue.toLowerCase())
                        );
                    }
                    return options;
                }}

                renderGroup={(params) => (
                    <Box key={params.key}>
                        {showselectall && (
                            <Box sx={{
                                backgroundColor: '#fff',
                                zIndex: 1,
                                px: 0.5,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <CustomizedCheckbox
                                    checked={isGroupSelected(params.group)}
                                    onChange={(event) => handleGroupSelectAll(params.group, event.target.checked)}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: '#212121',

                                    }}
                                >
                                    {params.group}
                                </Typography>
                            </Box>
                        )}
                        {params.children}
                    </Box>
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <CustomizedCheckbox
                            checked={selected}
                        />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: '#212121'
                            }}
                        >
                            {option.name}
                        </Typography>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        error={touch && error}
                        helperText={helperText}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: "14px",
                                color: "#3D4C5E",
                                borderRadius: '8px',
                                minHeight: "44px",
                                backgroundColor: '#FFFFFF',
                                '& fieldset': {
                                    borderColor: '#D6D6D7'
                                },
                                '&:hover fieldset': {
                                    borderColor: '#D6D6D7'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#3D8BF8'
                                }
                            },
                            minHeight: "44px",
                        }}
                        // Prevent input field from clearing on blur
                        InputProps={{
                            ...params.InputProps,
                            onBlur: (e) => {
                                params.InputProps.onBlur && params.InputProps.onBlur(e);
                                // Don't reset input value on blur
                            }
                        }}
                    />
                )}
                sx={{
                    '& .MuiAutocomplete-tag': {
                        borderRadius: '16px',
                        backgroundColor: '#F5F5F5',
                        height: '24px',
                        margin: '4px',
                        '& .MuiChip-label': {
                            fontSize: '0.8125rem',
                            color: '#536580'
                        },
                        '& .MuiChip-deleteIcon': {
                            display: 'none'
                        },
                        '& .MuiChip-root.Mui-disabled': {
                            opacity: 1
                        }
                    },
                    '& .MuiAutocomplete-popper': {
                        '& .MuiPaper-root': {
                            borderRadius: '8px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
                            border: '1px solid #E6E5E6'
                        }
                    },
                    '& .MuiAutocomplete-listbox': {
                        '& .MuiAutocomplete-option': {
                            padding: '6px 16px',
                            '&[aria-selected="true"]': {
                                backgroundColor: '#F5F5F5'
                            },
                            '&.Mui-focused': {
                                backgroundColor: '#F5F5F5'
                            }
                        }
                    }
                }}
            />
        </Grid>
    );
});

export default GroupedAutoComplete;