import { useCallback, useEffect, useState, } from "react";
import { Typography, Box, Button, Menu, MenuItem, Pagination, PaginationItem, TextField, InputAdornment } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import './OZDataGrid.css';
import { makeStyles } from '@mui/styles';
import { useLocation } from "react-router-dom";


const useStyles = makeStyles({
    pagination: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 1px',
        paddingBottom: '0px',
        justifyContent: 'space-between'
    },
    rowsPerPageMenuWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem'
    },
    rowsPerPageMenuButton: {
        border: '1px solid #546881 !important',
        color: 'black !important',
        backgroundColor: 'white !important',
        borderRadius: "12px !important",
        fontSize: '14px !important',
        minWidth: '103px !important',
        minHeight: '35px !important',
    },
    rowsPerPageMenuButtonIcon: {
        color: '#546881 !important'
    },
    rowsPerPageMenu: {
        borderRadius: '8px',
        padding: '0px 0px !important',
    },
    rowsPerPageMenuItem: {
        color: '#546881 !important',
        fontSize: '13px !important',
        border: 'solid !important',
        borderColor: 'rgb(0,0,0,0.1) !important',
        borderWidth: '1px 0 0px 0 !important',
        padding: '0.15rem 1rem !important',
        '&:nth-last-child(1)': {
            border: 'solid !important',
            borderColor: 'rgb(0,0,0,0.1) !important',
            borderWidth: '1px 0 1px 0 !important'
        }
    },
    goToContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        gap: '8px'
    },
    goToInput: {
        width: '58px',
        margin: '0 8px',
        '& .MuiOutlinedInput-root': {
            height: '31px',
            fontSize: '12px',
            background: 'white',
            borderRadius: '12px',
            color: '#000000',
            boxShadow: '0px 4px 4px 0px #00000040 inset',
            '& fieldset': {
                border: 'none',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '6px 10px',
            textAlign: 'center',
        },
        '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root:hover fieldset': {
            border: 'none',
        },
    }
});

const OZGridPagination = (props) => {

    useEffect(() => {
        setRowCount(props.rcount)
        setNoOfTotalData(props.totalData)
    }, [props])

    const getCurrrentPage = () => {
        if (props?.name === 'campaign_Data') {
            return 1;
        }
        let pageNum = sessionStorage.getItem("pageNo");
        if (pageNum) {
            return Number(pageNum);
        } else {
            return 1;
        }
    }
    const [page, setPage] = useState(getCurrrentPage());
    const [noOfRows, setRowCount] = useState(props.rcount);
    const [noOfTotalData, setNoOfTotalData] = useState(props.totalData);
    const [goToPage, setGoToPage] = useState('');
    const location = useLocation()

    useEffect(() => {
        if (props?.name === 'campaign_Data') {
            //not storing if campaign_Data table
        } else {
            sessionStorage.setItem("pageNo", page);
        }

    }, [page,])

    useEffect(() => {
        if (props?.gridRef?.current?.api) {
            props.gridRef.current.api.paginationGoToPage(page - 1);
        }
    }, [props.gridRef.current])

    useEffect(() => {
        let prevLocation = sessionStorage.getItem("pageLocation");
        let prevPageType = sessionStorage.getItem('pageType')
        if (prevPageType === 'campaign_Data' && props?.name === 'Outbound') {
            return;
        } else if (prevLocation !== location.pathname || (prevPageType !== 'undefined' && (props?.name !== prevPageType))) {
            setPage(1);
            sessionStorage.setItem("pageNo", 1);
            sessionStorage.setItem("pageLocation", location.pathname);
            sessionStorage.setItem('pageType', props?.name)
        }

    }, [location])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // if (props?.name === 'blockNumbers') {
        //     // localStorage.setItem("pageNo", newPage);
        // }
        if (props?.newPageNo) {
            props.newPageNo(newPage);
        }
        props.gridRef.current.api.paginationGoToPage(newPage - 1);
        if (props?.doPagination) {
            props.updateGridPage(newPage - 1);
        }
    };

    const handleGoToPage = () => {
        const totalPages = ((props.doPagination) ? Math.ceil(noOfTotalData / rowsPerPage) : Math.ceil(noOfRows / rowsPerPage)) || 1;
        const pageNum = parseInt(goToPage, 10);

        if (pageNum && pageNum > 0 && pageNum <= totalPages) {
            setPage(pageNum);
            if (props?.name === 'blockNumbers') {
                localStorage.setItem("pageNo", pageNum);
            }
            if (props?.newPageNo) {
                props.newPageNo(pageNum);
            }
            props.gridRef.current.api.paginationGoToPage(pageNum - 1);
            if (props?.doPagination) {
                props.updateGridPage(pageNum - 1);
            }
        }
    };

    const handleGoToInputChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setGoToPage(value);
    };

    const handleGoToInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleGoToPage();
        }
    };

    const onPageSizeChanged = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        if (props?.newRowPerPage) {
            props.newRowPerPage(event.target.value, page)
        }

        if (!props?.doPagination && props?.name !== "ResetCampaign" && props?.name !== "AdminForm") {
            if (event.target.value) {
                localStorage.setItem("ps", event.target.value);
            } else {
                localStorage.setItem("ps", 10);
            }
        }
        setPage(1);
        if (props?.doPagination) {
            props.updateGridPage(0);
        }
        props.gridRef.current.api.paginationGoToPage(0);
        // props.gridRef.current.api.paginationSetPageSize(Number(event.target.value));
        props.gridRef.current.api.paginationSetPageSize(Number(props?.doPagination || props?.name === "ResetCampaign" || props?.name === "AdminForm") ? event.target.value : localStorage.getItem("ps"));
        if (props?.doPagination) {
            // props.updateGridPageSize(Number(event.target.value));
            props.updateGridPageSize(Number(props?.doPagination || props?.name === "ResetCampaign" || props?.name === "AdminForm") ? event.target.value : localStorage.getItem("ps"));
        }
        setAnchorEl(null);
    }, []);

    const handleRowsPerPageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseRowsPerPageMenu = () => {
        setAnchorEl(null);
    };

    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsPerPage, setRowsPerPage] = useState(Number((props?.doPagination || props?.name === "ResetCampaign" || props?.name === "AdminForm") ? 10 : (localStorage.getItem("ps") ? localStorage.getItem("ps") : 10)));

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const displayRowsPerPageMenu = Boolean(anchorEl);

    const PaginationRenderIcon = (props) => {

        const Prev = () => <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 4.5L7 12L14.5 19.5" stroke={props.item.disabled ? "#7A7A7A" : "#546881"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        const Next = () => <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 4.5L16.5 12L9 19.5" stroke={props.item.disabled ? "#7A7A7A" : "#546881"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


        return (
            <PaginationItem
                components={{ previous: Prev, next: Next }}
                {...props.item}
            />
        )
    }


    return (
        <>
            {((props.doPagination) ? noOfTotalData >= 1 : noOfRows > 10) && (
                <Box className={classes.pagination}>
                    <Box className={classes.rowsPerPageMenuWrapper}>
                        <Button
                            id="rowsPerPageMenuButton"
                            aria-controls={displayRowsPerPageMenu ? 'rowsPerPageMenu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={displayRowsPerPageMenu ? 'true' : undefined}
                            variant="outlined"
                            className={classes.rowsPerPageMenuButton}
                            disableElevation
                            onClick={handleRowsPerPageMenuOpen}
                            endIcon={<KeyboardArrowDownOutlinedIcon className={classes.rowsPerPageMenuButtonIcon} />}
                        >
                            <Typography component={'span'} color={'#546881'} fontSize="12px" fontWeight="700">
                                {rowsPerPage}
                            </Typography>
                            <Typography component={'span'} color={'#000000'} fontSize="12px" fontWeight="400" ml={1}>
                                / Pages
                            </Typography>
                        </Button>
                        <Menu
                            elevation={0}
                            open={displayRowsPerPageMenu}
                            id="rowsPerPageMenu"
                            MenuListProps={{
                                'aria-labelledby': 'rowsPerPageMenuButton',
                                className: classes.rowsPerPageMenu
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        p: 0,
                                        mt: 1,
                                        backgroundColor: 'white',
                                        borderRadius: '12px',
                                        border: '1px solid #546881',
                                        minWidth: '110px !important',
                                        minHeight: '35px !important',
                                    },
                                },
                            }}
                            anchorEl={anchorEl}
                            onClose={handleCloseRowsPerPageMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            {/* <MenuItem className={classes.rowsPerPageMenuItem} onClick={onPageSizeChanged} value={5} disableRipple>
                                5
                            </MenuItem> */}
                            <MenuItem className={classes.rowsPerPageMenuItem} onClick={onPageSizeChanged} value={10} disableRipple>
                                10
                            </MenuItem>
                            <MenuItem className={classes.rowsPerPageMenuItem} onClick={onPageSizeChanged} value={15} disableRipple>
                                15
                            </MenuItem>
                            <MenuItem className={classes.rowsPerPageMenuItem} onClick={onPageSizeChanged} value={20} disableRipple>
                                20
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Pagination
                            sx={{
                                '& .MuiPaginationItem-previousNext': { color: '#3D8BF8', border: 'none', width: '30px !important' },
                                '& .MuiPaginationItem-previousNext.Mui-disabled': { color: '#7A7A7A' },
                                '& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page': {
                                    backgroundColor: 'none',
                                    border: 'none',
                                    width: '38px !important',
                                    height: '38px !important'
                                },
                                '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
                                    backgroundColor: '#E6F4FF !important'
                                }
                            }}
                            // count={Math.ceil(noOfRows / rowsPerPage) || 1}
                            count={((props.doPagination) ? Math.ceil(noOfTotalData / rowsPerPage) : Math.ceil(noOfRows / rowsPerPage)) || 1}
                            variant="outlined"
                            shape="circular"
                            // color="primary"
                            onChange={handleChangePage}
                            // siblingCount={1}
                            page={page}
                            // boundaryCount={1}
                            renderItem={(item) => (
                                <PaginationRenderIcon item={item} />
                            )}
                        />
                        <Box className={classes.goToContainer}>
                            <Typography color={'#000000'} fontSize="12px" fontWeight="400">Go to</Typography>
                            <TextField
                                className={classes.goToInput}
                                value={goToPage}
                                onChange={handleGoToInputChange}
                                onKeyDown={handleGoToInputKeyPress}
                                variant="outlined"
                                size="small"
                                inputProps={{ style: { textAlign: 'center' } }}
                            />
                            <Typography color={'#000000'} fontSize="12px" fontWeight="400">Page</Typography>
                        </Box>
                    </Box>
                </Box >
            )}
        </>
    )
}


export default OZGridPagination