import React, { useState, useEffect, useMemo, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography, Autocomplete, TextField, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { getIvrFlowCampaignWise, getIvrSkillDistribution, updateIvrSkillDistribution } from "../../../services/api-service";
import "./IVR_Skills.css";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { DropDownIcon } from "../BulkUpdateSettings/AgentSelector";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { X, Save, CornerDownLeft } from 'lucide-react';

const TIME_PERIODS = [
  'Day',
  'Night'
]

const commonAutocompleteProps = {
  popupIcon: <DropDownIcon />,
  placeholder: "Select",
  size: 'small',
  isOptionEqualToValue: (option, value) => option === value,
  clearIcon: null,
  disableCloseOnSelect: true,
  componentsProps: { paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } } },
  renderOption: (props, option, { selected, inputValue }) => <Box {...props} sx={{
    paddingLeft: "10px !important",
  }} className="oz-admin-bulk-update-content-body-controls-item-select-option">
    <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} width={"100%"} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>
      {typeof option === 'string' ? option : option?.flowName}
    </Typography>
  </Box>,
  renderInput: (params) => <TextField placeholder='Select'
    sx={{
      '& .MuiOutlinedInput-root': { fontSize: "14px", color: "#3D4C5E", borderRadius: '8px', minHeight: "36px" },
      minHeight: "36px"
    }}
    {...params}
  />
}

const IVRSkills = () => {
  const [loading, setLoading] = useState(false);
  const [ivrList, setIvrList] = useState([]);
  const [selectedIvr, setSelectedIvr] = useState(null);
  const [ivrSkillsData, setIvrSkillsData] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [distributionData, setDistributionData] = useState([]);
  const [locations, setLocations] = useState([]);

  const hasEditAccess = newAuthPages(NewAdminPages.IVR_SKILL_SPLIT_SELECTION) || newAuthSubAdminAccess(NewAdminPages.IVR_SKILL_SPLIT_SELECTION, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_SKILL_SPLIT_SELECTION, 'Edit');

  useEffect(() => {
    getIvrList();
  }, []);

  async function getIvrList() {
    setLoading(true);
    try {
      const resp = await getIvrFlowCampaignWise();
      setIvrList(resp.Data);
      setLoading(false);
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setIvrList([]);
      setLoading(false);
    }
  }

  const handleTimePeriodChange = (event, value) => {
    setSelectedTimePeriod(value);
    if (selectedIvr && selectedLanguage && value) {
      setSelectedTimePeriod(value);
    }
  }

  const handleLanguageChange = (event, value) => {
    setSelectedLanguage(value);
    if (selectedIvr && value) {
      setSelectedLanguage(value);
    }
  }

  const handleIvrChange = async (event, value) => {
    setSelectedIvr(value);
    if (!value) {
      setSelectedLanguage(null);
      setSelectedTimePeriod(null);
      return;
    }

    try {
      setLoading(true);

      const response = await getIvrSkillDistribution(value.flowId);

      if (response?.success === false || response?.data === null) {
        showErrorNotification(response?.message || "No data found");
        setLoading(false);
        setLanguages([]);
        setSelectedLanguage(null);
        setSelectedTimePeriod(null);
        return;
      }

      if (response?.data) {
        setIvrSkillsData(response.data);
        if (response.data.UI_Languages && Array.isArray(response.data.UI_Languages)) {
          setLanguages(response.data.UI_Languages);
        }
      } else {
        showErrorNotification("Invalid response format");
      }

      setLoading(false);
    } catch (e) {
      showErrorNotification("Failed to fetch IVR data: " + e.message);
      setLoading(false);
    }
  };

  const PercentageCellRenderer = (props) => {
    const isEditAllowed = useMemo(() => {
      if (props?.data) {
        let active = props.data.active[props.column.colId];
        return (active === "true" || active === true);
      }
      return false;
    }, [props.data]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [percentageValue, setPercentageValue] = useState(props.value || 0);

    if (props.value === undefined || props.value === null) {
      return <div className="oz-ivr-skills-empty-cell">-</div>;
    }

    const { error } = getTotalWithErrors(props);

    const toggleEditMode = () => {
      if (isEditAllowed) {
        setIsEditMode(!isEditMode);
      }
    }

    const handleSkillPercentageChange = (value) => {
      if (value === "") {
        setPercentageValue("");
        return;
      }
      const numericValue = parseInt(value);
      if (isNaN(numericValue)) {
        showErrorNotification("Please enter a valid number");
      } else if (numericValue < 0) {
        showErrorNotification("Percentage cannot be less than 0");
      } else if (numericValue > 100) {
        showErrorNotification("Percentage cannot be greater than 100");
      } else {
        setPercentageValue(numericValue);
      }
    }

    const savePercentage = () => {
      setDistributionData((prev) => {
        const updatedData = [...prev];
        const index = updatedData.findIndex(item => item.processName === props.data.processName);
        updatedData[index][props.column.colId] = percentageValue || 0;
        return updatedData;
      });
      props.setValue(percentageValue || 0);
      setIsEditMode(false);
    }

    return (
      <div className="oz-ivr-skills-percentage-cell" style={{
        cursor: isEditAllowed ? "pointer" : "default",
      }}>
        {!isEditMode ? (
          <div className="oz-ivr-skills-display-cell" onClick={() => {
            if (isEditAllowed) {
              setIsEditMode(true)
            }
          }}>
            <div className={`oz-ivr-skills-percentage-value ${error ? 'error' : ''}`}>
              {props.value || 0}%
            </div>
            {hasEditAccess && isEditAllowed && (
              <Button
                variant="text"
                onClick={toggleEditMode}
                className="oz-ivr-skills-edit-button"
                size="small"
              >
                Edit
              </Button>
            )}
          </div>
        ) : (
          <div className="oz-ivr-skills-display-cell">
            <TextField
              size="small"
              variant="standard"
              sx={{
                width: '40px',
                padding: '4px',
                '& .MuiInputBase-root': {
                  '&:before, &:after': {
                    borderBottom: '1px solid',
                    borderColor: error ? '#f44336' : '#3D4C5E'
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px solid',
                    borderColor: error ? '#f44336' : '#3D4C5E'
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'transparent'
                  }
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                  textAlign: 'center',
                  fontSize: '12px',
                  color: error ? '#f44336' : '#3D4C5E',
                  fontWeight: '600',
                  borderBottom: '1px solid',
                  borderColor: error ? '#f44336' : '#3D4C5E'
                }
              }}
              focused={isEditMode}
              type="number"
              value={percentageValue}
              onBlur={() => savePercentage()}
              onChange={(e) => handleSkillPercentageChange(e.target.value)}
              error={error}
              InputProps={{
                readOnly: !hasEditAccess,
                disableUnderline: true,
              }}
            />

            <div className="oz-ivr-skills-edit-actions">
              <IconButton
                onClick={toggleEditMode}
                className="oz-ivr-skills-discard-button"
                size="small"
              >
                <X size={18} strokeWidth={1} />
              </IconButton>
              <IconButton
                onClick={savePercentage}
                size="small"
                className="oz-ivr-skills-save-button"
                color="primary"
              >
                <CornerDownLeft size={18} strokeWidth={1} />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ActionCellRenderer = (params) => {
    const { error } = getTotalWithErrors(params);
    const [modified, setModified] = useState(isRowDataPercentageModified());

    function isRowDataPercentageModified() {
      let isModified = false;
      let rowData = params.data;
      let processName = rowData.processName;
      if (ivrSkillsData && selectedLanguage && selectedTimePeriod && distributionData && rowData) {
        let lobProcesses = ivrSkillsData.table.find(item => item.Language === selectedLanguage)?.lobProcesses;
        if (lobProcesses && typeof lobProcesses === 'object' && Object.keys(lobProcesses).length > 0 && lobProcesses[processName]) {
          let processData = lobProcesses[processName];
          let locationDistribution = processData.locationDistribution;
          if (locationDistribution && Array.isArray(locationDistribution) && locationDistribution.length > 0) {
            locationDistribution.forEach(locationItem => {
              if (!isModified && (locationItem.shift === selectedTimePeriod) && (rowData.hasOwnProperty(locationItem.location))) {
                if (rowData[locationItem.location] !== locationItem.percentage) {
                  isModified = true;
                }
              }
            });
          }
        }
      }
      return isModified;
    }

    async function handleSave() {
      if (error) {
        showErrorNotification("Total percentage is not 100%");
        return;
      }
      if (!modified) {
        showErrorNotification("No changes to save");
        return;
      }
      let rowData = { ...params.data };
      let processName = rowData.processName;

      if (ivrSkillsData && selectedLanguage && selectedTimePeriod && distributionData && rowData) {
        let lobProcesses = ivrSkillsData.table.find(item => item.Language === selectedLanguage)?.lobProcesses;
        if (lobProcesses && typeof lobProcesses === 'object' && Object.keys(lobProcesses).length > 0 && lobProcesses[processName]) {
          let processData = lobProcesses[processName];
          let locationDistribution = processData.locationDistribution;
          if (locationDistribution && Array.isArray(locationDistribution) && locationDistribution.length > 0) {
            locationDistribution.forEach(locationItem => {
              const location = locationItem.location;
              if ((locationItem.shift === selectedTimePeriod) && (rowData.hasOwnProperty(location))) {
                locationItem.percentage = rowData[location];
                locationItem.active = rowData.active[location];
              }
            });
          }
          console.log({ [processName]: processData, });
          setLoading(true);
          try {
            const response = await updateIvrSkillDistribution(selectedIvr.flowId, selectedIvr.flowName, selectedLanguage, selectedTimePeriod, { [processName]: processData, });
            if (response?.success === false || response?.error) {
              showErrorNotification(response?.message || response?.error || "Failed to update skill distribution");
            } else {
              showSuccessNotification("Skill distribution updated successfully");
              setModified(false);
            }
          } catch (e) {
            showErrorNotification("Failed to update skill distribution");
          } finally {
            setLoading(false);
          }
        }
      }

    }

    return <div className="oz-ivr-skills-action-cell">
      <Tooltip title={error ? "Total should be 100%" : modified ? "Percentage is modified, please save to update" : ""} color={'error'} className="oz-ivr-skills-action-cell-tooltip">
        <div style={{ cursor: error ? "not-allowed" : "pointer" }} onClick={() => handleSave()}>
          <IconButton size="small" disabled={error}>
            <Save size={18} strokeWidth={1} color={error ? "#f44336" : modified ? "#3D8BF8" : "#3D4C5E"} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  }

  function getTotalWithErrors(params) {
    let total = 0;
    if (params.data && locations && Array.isArray(locations) && locations.length > 0) {
      locations.forEach(location => {
        total += Number(params.data[location] || 0);
      });
    }
    const difference = 100 - total;
    const absoluteDifference = Math.abs(difference);
    const absoluteDifferencePercentage = Math.round(absoluteDifference * 10) / 10;
    return {
      total, difference, absoluteDifferencePercentage,
      error: total !== 100,
    };
  }

  const TotalCellRenderer = (params) => {
    const { total, error, absoluteDifferencePercentage, difference } = useMemo(() => {
      return getTotalWithErrors(params);
    }, [params.data, locations]);


    let statusColor = "#4caf50"; // green for correct
    if (error) {
      statusColor = "#f44336"; // red for error
    }

    return <div className={`oz-ivr-skills-total-cell ${error ? 'error' : ''}`} style={{ fontSize: error ? '11px' : '12px' }}>
      {total}%
      {error && (
        <span style={{ fontSize: error ? '11px' : '12px', color: statusColor }}>
          &nbsp; Total {difference > 0 ? `is less by ${absoluteDifferencePercentage}%` : `exceeds by ${absoluteDifferencePercentage}%`}
        </span>
      )}
    </div>
  }

  const ProcessNameCellRenderer = (params) => {
    const { error } = getTotalWithErrors(params);
    return <div className={`oz-ivr-skills-percentage-value ${error ? 'error' : ''}`}>
      {params.value}
    </div>
  }

  useEffect(() => {
    let tempLocations = [];
    let distributionData = [];
    if (ivrSkillsData && selectedLanguage && selectedTimePeriod) {
      const lobProcesses = ivrSkillsData.table.find(item => item.Language === selectedLanguage)?.lobProcesses;
      if (lobProcesses && typeof lobProcesses === 'object' && Object.keys(lobProcesses).length > 0) {
        for (const [key, value] of Object.entries(lobProcesses)) {
          const processName = key;
          const { total, locationDistribution } = value;
          let rowData = {
            processName,
            total,
            shift: selectedTimePeriod,
            active: {}
          }
          if (locationDistribution && Array.isArray(locationDistribution) && locationDistribution.length > 0) {
            locationDistribution.forEach(locationItem => {
              if (locationItem.shift === selectedTimePeriod) {
                tempLocations.push(locationItem.location);
                rowData[locationItem.location] = locationItem.percentage;
                rowData.active[locationItem.location] = locationItem.active;
              }
            });
          }
          distributionData.push(rowData);
        }
        setDistributionData(distributionData);
        setLocations([...new Set(tempLocations)]);
      }
    }
  }, [ivrSkillsData, selectedLanguage, selectedTimePeriod]);

  const processColumnDefs = useCallback(() => {
    let columnDefs = [];
    if (distributionData && Array.isArray(distributionData) && distributionData.length > 0) {
      columnDefs.push({
        field: 'processName',
        headerName: 'Process Name',
        cellRenderer: ProcessNameCellRenderer
      });
      if (locations && Array.isArray(locations) && locations.length > 0) {
        locations.forEach(location => {
          columnDefs.push({
            field: location,
            headerName: location,
            cellRenderer: PercentageCellRenderer
          })
        });
      }
      columnDefs.push({
        field: 'total',
        headerName: 'Total',
        minWidth: 160,
        cellRenderer: TotalCellRenderer
      });
      columnDefs.push({
        field: 'actions',
        headerName: 'Actions',
        cellRenderer: ActionCellRenderer
      });
    }
    return columnDefs;
  }, [locations, distributionData]);

  console.log({
    selectedIvr,
    selectedLanguage,
    selectedTimePeriod,
    ivrSkillsData,
    distributionData
  })

  return (
    <div className="oz-ivr-skills-container">
      <Typography
        sx={{
          fontweight: '400',
          fontSize: '12px',
          marginBottom: "5px"
        }}
      >
        Configurations
      </Typography>
      <Stack direction="row" sx={{ marginBottom: 2 }} spacing={1}>
        <Typography
          sx={{
            fontStyle: "Roboto",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "28.13px",
            marginBottom: "4px"
          }}
          flexGrow={1}
          alignSelf="center"
        >
          Volume Routing
        </Typography>
      </Stack>

      <Box sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '10px 14px 10px 14px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Stack spacing={.5} minWidth={"300px"}>
                <Typography ml={.3} fontSize={"12px"} fontWeight={500} color={"#3D4C5E"}>
                  Select IVR Flow
                </Typography>
                <Autocomplete
                  {...commonAutocompleteProps}
                  options={ivrList}
                  getOptionLabel={(option) => option?.flowName ?? ''}
                  value={selectedIvr}
                  onChange={handleIvrChange}
                  isOptionEqualToValue={(option, value) => option?.flowId === value?.flowId}
                  disableCloseOnSelect={false}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Stack spacing={.5} minWidth={"300px"}>
                <Typography ml={.3} fontSize={"12px"} fontWeight={500} color={"#3D4C5E"}>
                  Select Language
                </Typography>
                <Autocomplete
                  {...commonAutocompleteProps}
                  options={languages}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  disableCloseOnSelect={false}
                  disabled={!ivrSkillsData}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Stack spacing={.5} minWidth={"300px"}>
                <Typography ml={.3} fontSize={"12px"} fontWeight={500} color={"#3D4C5E"}>
                  Select Time Period
                </Typography>
                <Autocomplete
                  {...commonAutocompleteProps}
                  options={TIME_PERIODS}
                  value={selectedTimePeriod}
                  onChange={handleTimePeriodChange}
                  disableCloseOnSelect={false}
                  disabled={!ivrSkillsData || !selectedLanguage}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {loading ? (
            <div style={{ display: 'flex', padding: "8px", justifyContent: 'center', alignItems: 'center', height: '100%' }} >
              <LoadingScreen />
            </div>
          ) : null}
        </Box>
      </Box>
      {selectedIvr && selectedLanguage && selectedTimePeriod ? (
        distributionData?.length > 0 ? (
          <>
            <OZGridToolbar
              title={`Distribution for ${selectedLanguage} - ${selectedTimePeriod}`}
              rowCount={distributionData.length}
              dataGridRef={gridData}
              fileName="ivr_distribution"
            />
            <OZDataGrid
              height={"43vh"}
              useCustomHeight={true}
              columns={processColumnDefs()}
              data={distributionData}
              setGridRef={setGridData}
            />
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography color="text.secondary">No distribution data available</Typography>
          </Box>
        )
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <Typography color="text.secondary">Select IVR Flow, Language, and Time Period to view distribution data</Typography>
        </Box>
      )}
    </div>
  );
};

export default IVRSkills;
