import React, { useState, useMemo, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { deleteTemplateById, getEmailById, getSettingCategoryById, getSettingCategoryList, getSettingParamById, getSettingParamsList, getSmsById, getTemplates, getWhatsappById, } from "../../../services/api-service";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { Button, Divider, ListItemButton, Menu, Tooltip } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import TabList from '@mui/lab/TabList';
import { Stack, Typography } from '@mui/material';
import SettingParameterForm from './SettingParameterForm';
import OZDialog from '../../../components/admin/common/OZDialog'
// import WhatsappTemplateForm from './WhatsappForm';
// import SMSTemplateForm from './SmsTemplateForm';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import './Settings.css'
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { OZCustomToggleButton } from '../../../components/admin/common/OZCustomToggleButton';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import SettingCategoriesForm from './SettingCategories';


export function Settings(props) {
    const [addFormtype, setAddFormType] = useState("1");
    const [open] = useState(true)
    const [dialog, setDialog] = useState(false)
    const [menuValue, setMenuValue] = React.useState('1')
    const [showMenu, setMenu] = useState({ isNew: false, show: false });
    const [gridRef, setGridRef] = useState(null);
    const [rowData, setRowdata] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [data, setData] = useState({});

    const [showForm, setshowForm] = useState({ isNew: false, show: false });
    const [loading, setLoading] = useState(false);

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

    function ChooseForm(chooseMenuProps) {
        const handleClose = () => {
            chooseMenuProps.close('10');
        };

        return (
            <div>
                {chooseMenuProps.menuType === '1' && <SettingParameterForm emailTemplate={chooseMenuProps.email} onClose={handleClose} />}
                {chooseMenuProps.menuType === '2' && <SettingCategoriesForm emailTemplate={chooseMenuProps.whatsapp} onClose={handleClose} />}
            </div>
        )
    }

    const handleChange = (event, newMenuValue) => {
        setMenuValue(newMenuValue);
        changeColumn(newMenuValue);
    };

    const handleClose = () => {
        setDialog(false)

    };

    const handleClick = () => {
        setDialog(true)
    };

    const emailTemplate = () => {
        setMenuValue("1");
    };

    const whatsAppTemplate = () => {
        setMenuValue("2");
    };

    function MoreVertColumnRenderer(props) {
        const [anchorE1, setAnchorE1] = useState(false);
        const anchorRef = useRef();
        const [openDelete, setopenDelete] = useState(false)

        const morevertClick = () => {
            setTimeout(() => setAnchorE1(anchorRef?.current), 1);
        };

        const handleClose = () => {
            setAnchorE1(false);
        };

        function onClickDelete() {
            setAnchorE1(false);
            setopenDelete(true)
        }

        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    width: '105px',
                    fontWeight: 400,
                    height: '14px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        async function onClickDialogDelete(type) {
            try {
                deleteTemplateById(props.data.id).then((resp) => {
                    if (resp.Status === "Success") {
                        if (type === "whatsapp") {
                            closeWhatsappTemplateForm();
                            getCategoryList();
                        } else if (type === "sms") {
                            closeSMSTemplateForm();
                            // getSmsTemplate();
                        }
                        showSuccessNotification(resp.Message);
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }

                }).catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                })
            }
            catch (e) {
                showErrorNotification(JSON.stringify(e));
            }
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{
                    width: "600px",
                    fontSize: "12px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}>{props.value}</span>
                {(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full')) && <img className="delMoreIcon"
                    onClick={morevertClick}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit">
                </img>}
                <div ref={anchorRef}>
                    <Menu anchorEl={anchorE1} open={Boolean(anchorE1)} onClose={handleClose} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "103px",
                            height: "35px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-0.6525rem",
                            marginTop: ".2rem",
                        },
                    }}>
                        <ListItemButton
                            onClick={onClickDelete}
                            sx={styleListItemButton}
                            disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')}
                        >
                            Delete
                        </ListItemButton>
                    </Menu>
                    {openDelete &&
                        <DeleteDialog
                            open={openDelete}
                            onClose={() => { setopenDelete(false) }}
                            title={(props.data.type === "whatsapp") ? "Delete Whatsapp Template" : "Delete SMS Template"}
                            content={(props.data.type === "whatsapp") ? `This will delete Whatsapp Template (${props.data.name}) permanently. You cannot undo this action.` : `This will delete SMS Template (${props.data.name}) permanently. You cannot undo this action.`}
                            deleteFunction={() => onClickDialogDelete(props.data.type)}
                        />
                    }
                </div>
            </div>
        );
    }

    const continueClicked = (value) => {
        if (value === '1') {
            showTemplateForm('1');
        }
        else if (value === '2') {
            showTemplateForm('2');
        }
    };

    const showTemplateForm = (formValue) => {
        setMenu({ isNew: true, show: true });
        handleClose();
        switch (formValue) {
            case '1':
                setAddFormType('1');
                break;

            case '2':
                setAddFormType('2');
                break;
            default:
        }
    };

    async function getSettingParams(type) {
        setLoading(true);
        try {
            getSettingParamsList()
                .then((resp) => {
                    if (resp.Status === "Success") {
                        setRowdata(resp.Data);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setRowdata([]);
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
            setRowdata([]);
        }
    };



    async function getCategoryList(type) {
        setLoading(true);
        try {
            getSettingCategoryList().then((resp) => {
                if (resp.Status === "Success") {
                    setRowdata(resp.Data);
                }
                setLoading(false);
            })
                .catch((e) => {
                    setRowdata([]);
                    setLoading(false);
                });;
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
            setRowdata([]);
        }
    };

    let styleToggleButtonInput = useMemo(
        () => ({
            color: "#212121",
            minWidth: "64px",
            heigh: "14px",
            fontFamily: "Roboto",
            fontSize: " 12px",
            fontWeight: 400,
            lineHeight: "14px",
            letterSpacing: "0em",
            textAlign: "center",
        }),
        []
    );

    let toggleStyle = useMemo(
        () => ({
            backgroundColor: "#F5F8FA",
            minWidth: "9rem",
            height: "30px",
            borderRadius: 0,
            '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white',
                backgroundColor: '#FFFF',
            }

        }),
        []
    );

    const styleListItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                width: '374px',
                height: '40px',
                borderRadius: "0.5rem",
                marginBottom: "2px",
                marginLeft: "1.1rem",
                marginRight: "1.2rem",
                fontStyle: "normal",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                lineHeight: "0.875rem",
                paddingLeft: '12px'
            },
        }),
        []
    );

    useEffect(() => {
        changeColumn(menuValue);
    }, []);

    const getNameRenderer = (props) => {
        return (
            // <Tooltip title={props?.value ? props?.value : ""}>
            <span style={{ color: '#3D8BF8', paddingLeft: '10px' }}>{props.value}</span>
            // </Tooltip>
        )
    };

    const getValueRenderer = (props) => {
        console.log(props.value)
        return (
            <span>{typeof props.value === 'string' ? props.value : props.value?.name}</span>
        )
    };

    function OptionsRenderer(props) {

        const gridItemStyle = useMemo(
            () => ({
                fontWeight: 400,
                fontSize: "12px",
                color: "#000000"
            }),
            []
        );

        const agentNames = props?.value?.split(',') || [];

        let agentName;
        if (agentNames.length > 1) {
            const agentNamesLastTwo = agentNames.slice(-1).join(',');
            const remainingCount = agentNames.length - 1;
            const remainingAgentNames = agentNames.slice(0, -1).join(',');
            agentName = (
                <>
                    <div>
                        {agentNamesLastTwo}
                        <span key="badge" className="badge" title={remainingAgentNames}>
                            {`+${remainingCount}`}
                        </span>
                    </div>
                </>
            )
        }
        else {
            agentName = agentNames.join(',');
        }
        return (<Typography style={{ ...gridItemStyle }}>{agentName}</Typography>)
    }

    function changeColumn(index) {
        switch (index) {
            case "1":
                setColumnDefs([
                    {
                        field: 'code', headerName: "Setting Code", cellRenderer: getNameRenderer,
                    },
                    {
                        field: 'name', headerName: "Setting Name", cellRenderer: getValueRenderer,
                    },
                    {
                        field: 'inputType', headerName: "Input Type", cellRenderer: getValueRenderer,
                    },
                    {
                        field: 'defaultValue', headerName: "Default Value", cellRenderer: getValueRenderer,
                    },
                    {
                        field: 'inputOptions', headerName: "Input Options", cellRenderer: OptionsRenderer, minWidth: 190, maxWidth: 400
                    },
                    {
                        field: 'parameterType', headerName: "Category", cellRenderer: getValueRenderer,
                    },
                ]);
                getSettingParams()
                break;

            case "2":
                setColumnDefs([
                    {
                        field: 'name', headerName: "Category Name", cellRenderer: getNameRenderer,
                    },
                ]);
                getCategoryList()
                break;
            default:
        }
    };

    let buttonStyles = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            width: "5.68rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    function gridTitle() {
        if (menuValue === "1") {
            return ("List of Setting Parameters");
        } else if (menuValue === "2") {
            return ("List of Setting Categories");
        }
    };

    const closeWhatsappTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    const closeEmailTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    const closeSMSTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    async function onClickRow(rowItem) {
        setLoading(true);
        setshowForm({ isNew: false, show: true });
        setData({});
        switch (menuValue) {
            case "1":
                try {
                    getSettingParamById(rowItem.data.id)
                        .then((resp) => {
                            if (resp.Status === "Success") {
                                setData(resp.Data);
                            }
                            setLoading(false);
                        })
                        .catch((e) => {
                            setData({});
                            setLoading(false);
                        });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;

            case "2":
                try {
                    getSettingCategoryById(rowItem.data.id)
                        .then((resp) => {
                            if (resp.Status === "Success") {
                                setData(resp.Data);
                            }
                            setLoading(false);
                        })
                        .catch((e) => {
                            setData({});
                            setLoading(false);
                        });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;
            default:
        }
    };

    return (
        <div className="ozAdminEmailContainer">
            <div >
                <Stack direction="row" sx={{ marginBottom: 2, }} spacing={1} justifyContent="space-between"  >
                    <Typography sx={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '28px'
                    }} flexGrow={1} alignSelf='center'>Settings</Typography>
                    <div>
                        <Button
                            id="basic-button"
                            sx={buttonStyle}
                            variant="contained"
                            style={{ marginTop: '10px', width: '158px' }}
                            onClick={handleClick}
                            startIcon={<AddBoxOutlinedIcon />} >
                            New Setting
                        </Button>
                        {dialog && <  OZDialog
                            hideActions={true}
                            onClose={handleClose}
                            open={open}
                            title='Settings ' >
                            <Typography sx={{
                                fontSize: '12px', marginBottom: '8px'
                            }} > Choose a type of Setting to create</Typography>
                            <Box sx={{
                                border: '1px solid #D6D6D7',
                                borderRadius: '8px',
                                background: '#FFFFFF',
                                width: '414px',
                                height: '145px'
                            }} >
                                <ListItemButton
                                    onClick={() => { emailTemplate(); changeColumn("1") }}
                                    selected={"1" === menuValue}
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "1" ? "#3D8BF8" : "#212121", marginTop: "10px"
                                    }}>
                                    Setting Parameters
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "2" ? "#3D8BF8" : "#212121",
                                    }}
                                    onClick={() => { whatsAppTemplate(); changeColumn("2") }}
                                    selected={"2" === menuValue} >
                                    Setting Categories
                                </ListItemButton>
                            </Box >
                            <Stack marginTop='18px' marginLeft={'218px'} direction={'row'} spacing='16px'>
                                <Button sx={{ ...buttonStyles }} variant='outlined' onClick={handleClose} >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => continueClicked(menuValue)}
                                    sx={{ ...buttonStyles }}>
                                    Continue
                                </Button>
                            </Stack>
                        </OZDialog>}

                    </div>
                </Stack>
                <div style={{ display: "flex", height: "30px", marginLeft: '3px' }}>
                    <TabContext value={menuValue}>
                        <TabList
                            TabIndicatorProps={{
                                sx: { backgroundColor: "#008EFF", marginBottom: "18px" },
                            }}
                            onChange={handleChange} aria-label="lab API tabs example">

                            <OZCustomToggleButton
                                sx={{ ...toggleStyle, borderRight: 0 }}
                                value="1">
                                <Typography sx={styleToggleButtonInput}>Setting Parameters</Typography>
                            </OZCustomToggleButton>
                            <OZCustomToggleButton
                                sx={{ ...toggleStyle, borderRight: 0 }}
                                value="2">
                                <Typography sx={styleToggleButtonInput}>Setting Categories</Typography>
                            </OZCustomToggleButton>
                        </TabList>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
                        </span>
                    </TabContext>
                </div>
                <div className="oz-admin-Template-container">
                    {menuValue === "1" && showForm.show && showForm.isNew && (
                        <>
                            <SettingParameterForm emailTemplate={getSettingParams} onClose={closeEmailTemplateForm} />
                        </>
                    )}
                    {menuValue === "2" && showForm.show && showForm.isNew && (
                        <>
                            <SettingCategoriesForm onClose={closeWhatsappTemplateForm} emailTemplate={getCategoryList} />
                        </>
                    )}
                    {loading ?
                        (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                <LoadingScreen />
                            </div>
                        )
                        :
                        (
                            <>
                                {menuValue === "1" && showForm.show && !showForm.isNew && (
                                    <>
                                        <SettingParameterForm emailTemplate={getSettingParams} onClose={closeEmailTemplateForm} data={data} />
                                    </>
                                )}
                                {menuValue === "2" && showForm.show && !showForm.isNew && (
                                    <>
                                        <SettingCategoriesForm onClose={closeWhatsappTemplateForm} emailTemplate={getCategoryList} data={data} />
                                    </>
                                )}
                                <>
                                    <OZGridToolbar
                                        title={gridTitle()}
                                        searchPlaceholder={"Search by name"}
                                        rowCount={rowData.length}
                                        dataGridRef={gridRef}
                                        fileName='Admin Creation'
                                    />
                                    {showMenu.show && showMenu.isNew && (
                                        <ChooseForm email={getSettingParams} whatsapp={getCategoryList} menuType={addFormtype} close={setAddFormType} />
                                    )}
                                    <OZDataGrid
                                        height="26.3rem"
                                        columns={columnDefs}
                                        data={rowData}
                                        rowClickCallback={onClickRow}
                                        setGridRef={setGridRef}
                                    />
                                </>
                            </>
                        )
                    }

                </div>
            </div>
        </div >
    );
}