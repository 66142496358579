import React, { useState, useRef, useEffect, useMemo, memo } from 'react';
import { Box, Button, CircularProgress, IconButton, Menu, MenuItem, Slider, Stack, Tooltip } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../../static/icons/screen-recording.svg';
import { ReactComponent as PauseIcon } from '../../../../static/icons/rec-pause.svg';
import { ReactComponent as PlayIcon } from '../../../../static/icons/rec-play.svg';
import { ReactComponent as PlayLiveIcon } from '../../../../static/icons/play.svg';
import { ReactComponent as SkipBackIcon } from '../../../../static/icons/rec-rev.svg';
import { ReactComponent as SkipForwardIcon } from '../../../../static/icons/rec-forward.svg'
import { ReactComponent as FullScreeIcon } from '../../../../static/icons/rec-fullscreen.svg'
import { ReactComponent as FullScreeLiveIcon } from '../../../../static/icons/live-fullscreen.svg'
import { ReactComponent as CloseIcon } from '../../../../static/icons/closewidget.svg'

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { SC_RECORDING_URL } from '../../../../utils/properties';
import { getSecuredRecordingUrl } from '../../../../services/api-service';

interface VideoPlayerProps {
  url: string;
  agentId?: string;
  width?: number;
  height?: number;
  interval?: number;
  failCount?: number;
  isLive?: boolean;
}

interface VideoPlayerIconProps {
  row: {
    videoRecordingURL?: string;
    liveRecordingData?: any;
  };
  icon?: React.ReactNode | undefined;
  isLive?: boolean;
  onClose?: () => void;
  defaultOpen?: boolean;
  hideIcon?: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    opacity: '.2 !important'
  },
  '& .MuiPaper-root': {
    borderRadius: '12px',
    width: '566px',
    maxWidth: '566px',
    maxHeight: '493px',

  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: '#1D242D',
    paddingBottom: '0px'
  },
  '& .MuiDialogTitle-root': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }

}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  paddingTop: '5px',
  paddingBottom: '5px',
  color: '#99A0A8',
  height: 4,
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    background: 'conic-gradient(from 180deg at 50% 50%, #D9D9D9 0deg, #737373 360deg)',
    transition: '.4s cubic-bezier(.47,1.64,.41,.8)',
    '&::before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },

    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: .28,
  },

}));

let liveFeedInterval: any = null;

// VideoPlayer Component
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url = '', agentId, width = 800, height = 380, interval = 1, failCount = 3, isLive = false
}) => {
  console.log({ url, isLive })
  const [isPlaying, setIsPlaying] = useState(isLive);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [error, setError] = useState<string>('');
  const [failCounter, setFailCounter] = useState<number>(0);
  const [imageSource, setImageSource] = useState<string>('');

  const iv = new Uint8Array([..."kpt9d16c511b0a3cab4f5fe2fd5d6a434fa"].map((char) => char.charCodeAt(0)));
  const algorithm = { name: "AES-GCM", length: 256 };
  const salt = new Uint8Array([...atob("98HLecrKyCHL7HAdv4Cb7qKT1py3Opd88ZqMutsspvk=")].map((char) => char.charCodeAt(0)));

  const deriveKey = async () => {
    return window.crypto.subtle.importKey("raw", salt, algorithm.name, false, ["encrypt", "decrypt"]);
  };

  const generateToken = async (message: string) => {
    const derived = await deriveKey();
    const encoded = new Uint8Array([...message].map((char) => char.charCodeAt(0)));
    const encrypted = await window.crypto.subtle.encrypt({ name: algorithm.name, iv }, derived, encoded);
    return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
  };

  const streamAgentFeed = async () => {
    if (!agentId) {
      handleError("Error. Invalid input passed, retry again");
      return;
    }
    const ts = new Date().getTime();
    try {
      const response = await fetch(`${url}/files?live=1&agentId=${agentId}&date=${ts}`, {
        method: 'GET',
        headers: {
          'x-api-token': await generateToken(`${agentId}:${ts}`)
        }
      });
      if (!response.ok) throw new Error('Failed to fetch image');
      const blob = await response.blob();
      setImageSource(URL.createObjectURL(blob));
      setError('');
      setFailCounter(0);
    } catch (error) {
      console.error(error);
      handleError("Error. Paused due to streaming error. Click on Play again to Retry");
    }
  };

  const handleError = (message: string) => {
    setError(message);
    setIsPlaying(false);
    setFailCounter((prev) => prev + 1);
  };

  useEffect(() => {
    if (!isLive) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const handleTimeUpdate = () => setCurrentTime(videoElement.currentTime);
      const handleLoadedMetadata = () => setDuration(videoElement.duration);
      const handleEnded = () => setIsPlaying(false);

      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('ended', handleEnded);

      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [isLive]);

  useEffect(() => {
    if (isLive) {
      if (liveFeedInterval) {
        clearInterval(liveFeedInterval);
      }
      if (isPlaying) {
        liveFeedInterval = setInterval(streamAgentFeed, interval * 1000);
      } else {
        if (liveFeedInterval) {
          clearInterval(liveFeedInterval);
        }
      }
    }
    return () => {
      if (liveFeedInterval) {
        clearInterval(liveFeedInterval);
      }
    }
  }, [isPlaying, isLive]);

  useEffect(() => {
    if (error && failCounter < failCount) {
      setTimeout(() => {
        setIsPlaying(true);
      }, 1000);
    }
  }, [error, failCounter]);

  const togglePlay = () => {
    if (isLive) {
      isPlaying ? pause() : play();
    } else {
      if (!videoRef.current) return;
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  function play() {
    setFailCounter(0);
    setIsPlaying(true);
  };

  const pause = () => {
    setIsPlaying(false);
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const skip = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime += seconds;
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  if (!url) {
    return (
      <Box display={'flex'} gap={1} justifyContent={'center'} p={4} >
        <Typography fontWeight={600} color={'white'}>Error:</Typography>
        <Typography fontWeight={400} color={'white'}>No video URL provided.</Typography>
      </Box>
    );
  }

  function showFullscreenDiv() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      return;
    }
    const fullscreenDiv = document.getElementById("ozScreenRecVideoPlayer");

    if (fullscreenDiv) {
      fullscreenDiv.requestFullscreen();
    } else {
      console.error("Fullscreen div not found.");
    }
  }

  return (
    <Box id="ozScreenRecVideoPlayer">
      {isLive && imageSource ?
        <img
          src={imageSource}
          className="ozScreenRecVideoPlayer_canvas"
          width={width}
          height={height}
          style={{ width: '100%', height: '100%', borderRadius: '8px', border: '1px solid #D6D9DC' }}
          alt="Live Feed"
        />
        :
        <video ref={videoRef} style={{ width: '100%', height: '100%', borderRadius: '8px', border: '1px solid #D6D9DC' }}>
          <source src={url} type="video/mp4" />
        </video>
      }
      <Box >
        {!isLive && <Stack sx={{ paddingLeft: '2px', paddingRight: '8px' }}>
          <CustomSlider
            aria-label="time-indicator"
            size="medium"
            value={currentTime}
            min={0}
            step={.1}
            max={duration}
            onChange={(_, value) => {
              setCurrentTime(value as number);
              if (videoRef.current) {
                videoRef.current.currentTime = parseFloat(value.toString());
              }
            }}

          />
        </Stack>}
        <Stack direction={'row'} justifyContent={'space-between'} ml={-1.5} mr={-1.5}>
          <Stack direction={'row'} >
            <IconButton onClick={togglePlay}  >
              {isPlaying ? <PauseIcon /> : isLive ? <PlayLiveIcon /> : <PlayIcon />}
            </IconButton>
            {!isLive && <IconButton onClick={() => skip(-10)}>
              <SkipBackIcon />
            </IconButton>}
            {!isLive && <IconButton onClick={() => skip(10)}>
              <SkipForwardIcon />
            </IconButton>}
            {isLive && <Stack direction={"row"} alignItems={'center'} gap={.3} mb={'8px'} >
              <Box sx={{ background: "#E65B5C", width: '5px', height: '5px', borderRadius: '50%', }} />
              <Typography color={'#FFFFFF'} fontWeight={400} fontSize={'10px'}>
                LIVE
              </Typography>
            </Stack>}
          </Stack>
          {isLive && error && <Typography color={'#E65B5C'} fontWeight={400} fontSize={'12px'} mt="8px">
            {error}
          </Typography>
          }
          <Stack direction={'row'} alignItems={'center'} >
            {!isLive && <Typography color={'#B2BBC6'} fontWeight={400} fontSize={'12px'} mb={'8px'}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>}
            <IconButton onClick={() => showFullscreenDiv()} >
              {isLive ? <FullScreeLiveIcon /> : <FullScreeIcon />}
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </Box >
  );
};

const VideoPlayerIcon = memo(function VideoPlayerIcon({ row, icon, isLive = false, onClose, defaultOpen = false, hideIcon = false }: VideoPlayerIconProps) {

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [selectedUrlIndex, setSelectedUrlIndex] = useState<number | null>(null);
  const [anchorVideo, setAnchorVideo] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [secureUrl, setSecureUrl] = useState<string>('');

  const videoUrl: string | string[] = useMemo(() => {
    const url = row?.videoRecordingURL || '';
    return (url.includes(',') && url.split(',').length > 1) ? url.split(',') : url;
  }, [row]);
  const isMultipleVideos = Array.isArray(videoUrl);
  let selectedVideoUrl = isLive ? SC_RECORDING_URL || "" : (isMultipleVideos) ? selectedUrlIndex !== null ? videoUrl[selectedUrlIndex] : "" : videoUrl as string;
  const agentId = row?.liveRecordingData?.AgentUniqueId || '';
  const open = Boolean(anchorVideo);
  console.log({ secureUrl, selectedVideoUrl })
  useEffect(() => {
    if (defaultOpen) setIsOpen(true);
  }, [defaultOpen]);

  useEffect(() => {
    try {
      if (selectedVideoUrl && !isLive && isOpen) {
        setLoading(true);
        getSecuredRecordingUrl({ audioUrl: selectedVideoUrl.trim(), type: "video" }).then((resp) => {
          if (resp?.data) {
            setSecureUrl(resp?.data);
          }
          setLoading(false);
        }).catch((error) => {
          console.error(error);
          setLoading(false);
          setSecureUrl("");
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSecureUrl("");
    }
  }, [selectedVideoUrl, isLive, isOpen]);

  const handleClick = (event: any) => setAnchorVideo(event.currentTarget);
  const handleCloseMenu = () => setAnchorVideo(null);

  function getFileName(url: any) {
    if (!url) return url;
    try {
      const pathname = new URL(url).pathname;
      const fileName = pathname.split('/').pop();
      const fileNameWithoutExtension = fileName ? fileName.split('.').slice(0, -1).join('.') : pathname;
      let name = fileNameWithoutExtension || fileName;
      if (name === "video" || name === "video.mp4" || !name) {
        return pathname.split('/')[1].split("_")[1] || pathname; // call id or pathname
      }
      return name;
    } catch (e) {
      console.error("Failed to get Filename", e);
      return url;
    }
  }

  const videTitleStyle = useMemo(() => ({
    fontSize: '14px',
    color: '#000000',
    fontWeight: '400',
    backgroundColor: '#E6F4FF'
  }), []);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedUrlIndex(null);
    setSecureUrl('');
    if (onClose) onClose();
  }

  const VideoIconButton = ({ url, index }: { url: string, index?: number }) => {
    return (
      <Tooltip title={isLive ? "" : url ? `View recording ${getFileName(url)}` : "No recording url available"}>
        <div>
          <IconButton onClick={() => {
            setIsOpen(true);
            typeof index === 'number' && setSelectedUrlIndex(index);
          }}
            disabled={isLive ? false : !Boolean(url)}
          >
            {icon || <DownloadIcon />}
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <React.Fragment>
        {!hideIcon && isMultipleVideos ? <Stack minWidth={'fit-content'} sx={{}}>
          <Box display={'flex'}>
            <div style={{ marginRight: '12px' }}>
              <VideoIconButton url={videoUrl[0] as string} index={0} />
            </div>
            <Button size="small" variant="contained" onClick={handleClick} sx={{ minWidth: 'fit-content' }}>
              +{videoUrl.length - 1}
            </Button>
            <Menu anchorEl={anchorVideo} open={open} onClose={handleCloseMenu}>
              {videoUrl.map((url: string, index: number) => (index !== 0 && <MenuItem key={index}>
                <VideoIconButton url={url} index={index} />
              </MenuItem>
              ))}
            </Menu>
          </Box>
        </Stack>
          :
          <VideoIconButton url={selectedVideoUrl as string} />
        }
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle sx={{ ...videTitleStyle, m: 0, p: 1, }} id="customized-dialog-title">
            {(selectedVideoUrl && !isLive) ? <Box display={'flex'} gap={.8} alignItems={"center"}>
              Viewing
              {
                <Typography sx={{
                  ...videTitleStyle,
                  fontSize: '12px',
                  fontWeight: '500',
                }}>{getFileName(selectedVideoUrl)}</Typography>
              }
              screen
            </Box> : <Box display={'flex'} gap={.8}>{isLive ? `Viewing ${row?.liveRecordingData?.AgentId} Screen` : "Video Player"}</Box>}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size='small'
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 5,
            })}
          >
            <CloseIcon width={18} height={18} />
          </IconButton>
          <DialogContent dividers className='ozScreenRecVideoPlayerDialogContent'>
            {loading ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} p={3}>
              <CircularProgress />
            </Box> : <VideoPlayer url={isLive ? selectedVideoUrl : secureUrl} agentId={agentId || ""} isLive={isLive} />}
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
});

export default VideoPlayerIcon;