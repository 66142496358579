import { useEffect, useMemo, useRef, useState } from "react";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { Button, Menu, MenuItem, Stack, Typography, Tooltip } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { deleteFeatureById, getFeatureById, getFeatureList } from "../../../services/api-service";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import FeatureForm from "./FeatureForm";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

export default function Features() {

    const [featuregridRef, setFeatureGridRef] = useState(null);
    const [featurerowData, setFeatureRowData] = useState(null);
    const [openDrawer, setDrawerState] = useState(false);
    const [openDailogState, setDailogState] = useState(false);
    const [showFeatureAddForm, setShowFeatureAddForm] = useState({ isNew: false, show: false })
    const [loading, setLoading] = useState(false);

    const [featureData, setFeatureData] = useState([]);
    useEffect(() => {
        getFeatureListFunction();
        localStorage.removeItem("featureForm");
    }, [])

    const handleCloseForm = () => {
        setShowFeatureAddForm(false);
        setShowFeatureAddForm({ isNew: false, show: false });
    }

    async function getFeatureListFunction() {
        setLoading(true);
        try {
            getFeatureList().then((resp) => {
                if (resp.Status === "Success" && resp.Data && resp.Data.length > 0) {
                    setFeatureData(resp.Data);
                } else {
                    setFeatureData([])
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                setFeatureData([])
            })
        } catch (e) {
            setLoading(false);
            showErrorNotification("Error in the FeatureList");
        }
    }

    async function onClickRow(rowItem) {
        setFeatureRowData(null);
        setLoading(true);
        setDailogState(false);
        setDrawerState(true);
        setShowFeatureAddForm({ isNew: false, show: true });
        try {
            const response = await getFeatureById(rowItem.data.id);
            if (response.Status === "Success" && response.Data) {
                setFeatureRowData(response.Data);
            } else {
                setFeatureRowData(null);
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            setFeatureRowData(null);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteFeature(id, name) {
        setLoading(true);
        setDailogState(false);
        setDrawerState(true);
        setShowFeatureAddForm({ isNew: false, show: false });
        try {
            const response = await deleteFeatureById(id);
            if (response.Status === "Success") {
                showSuccessNotification(response.Message);
                getFeatureListFunction();
            } else {
                setLoading(false);
                showErrorNotification(JSON.stringify(response.Message));
            }
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    const DateRenderer = (props) => {
        const date = new Date(props.data.createdDate);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

        return (
            <label>{date.toLocaleString("en-GB", options) || '-'}</label>
        );
    };
    const GetNameRenderer = (props) => {
        return (
            // <Tooltip title={props?.value ? props?.value : ""}>
            <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
            // </Tooltip>
        )
    }

    const MouseEnterWidget = (props) => {
        const [ivranchorEl, setAnchorEl] = useState(false);
        const open = Boolean(ivranchorEl);
        const anchorRef = useRef();
        const [deleteMode, setDeleteMode] = useState(false);

        const morevertclick = () => {
            setTimeout(() => setAnchorEl(anchorRef?.current), 1);
        };
        const handleClose = () => {
            setAnchorEl(false);
        };

        const onClickDelete = () => {
            setAnchorEl(false);
            setDeleteMode(true);
        };
        const onClickDialogDelete = (id, name) => {
            deleteFeature(id, name);
            setDeleteMode(false);
        };
        const onClickDialogClose = () => {
            setDeleteMode(false);
        };

        const styleMenuItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '18px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "8px",
                },
            }),
            []
        );

        const date = new Date(props.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

        return (
            <div className="oz-administration-grid-cellrenderer-container">
                <label>{date.toLocaleString("en-GB", options)}</label>
                <img className="gridMorevertIcon" ref={anchorRef} style={{ marginTop: "2px", marginLeft: '0.625rem', alignSelf: 'center' }}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                    alt="edit"
                    onClick={morevertclick}
                ></img>
                <div ref={anchorRef}>
                    <Menu anchorEl={ivranchorEl} open={open} onClose={handleClose} sx={{
                        maxHeight: "70vh",
                        "& .MuiPaper-root": {
                            backgroundColor: "white",
                            width: "133px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "white",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-1.5625rem",
                        },
                    }} >
                        <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={onClickDelete}>Delete</MenuItem>
                    </Menu>
                </div>
                {
                    deleteMode && <DeleteDialog title={"Delete Feature"} content={`This will delete (${props.data.name}) Feature permanently. You cannot undo this action.`} onClose={onClickDialogClose}
                        open={true} deleteFunction={() => onClickDialogDelete(props.data.id, props.data.name)} />
                }

            </div>
        );
    };

    const widgetcolumnDefs = ([
        { headerName: 'Feature Name', field: 'name', cellRenderer: GetNameRenderer },
        { headerName: 'Feature Description', field: 'description' },
        { field: 'createdDate', headerName: 'Created Date', cellRenderer: DateRenderer },
        { field: 'updatedDate', headerName: 'Updated Date', cellRenderer: MouseEnterWidget },
    ])

    const showSkillForm = () => {
        setShowFeatureAddForm({ isNew: true, show: true });
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "8px",
            height: "30px",
            fontSize: "11px",
            fontWeight: 400,
            width: '8rem',

        }),
        []
    );

    return (
        <div className="oz-administration-container">
            <Typography
                sx={{
                    fontweight: '400',
                    fontSize: '12px',
                    marginBottom: "5px"
                }}
                flexGrow={1}
                alignSelf="center"
            >
                Administration
            </Typography>

            <Stack direction="row" sx={{ marginBottom: 2, paddingLeft: '3px', }}>
                <Typography sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '28px'
                }} flexGrow={1} alignSelf='center'>Features</Typography>
                {
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={showSkillForm}
                    >
                        New Feature
                    </Button>
                }
            </Stack>

            <OZGridToolbar title="List of Features"
                searchPlaceholder='Widget'
                rowCount={featureData.length}
                dataGridRef={featuregridRef}
                hideDownload={true}
            />
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <>
                    <OZDataGrid
                        columns={widgetcolumnDefs}
                        data={featureData}
                        setGridRef={setFeatureGridRef}
                        height="23.5rem"
                        rowClickCallback={onClickRow}
                    />
                </>
            }

            {showFeatureAddForm.show && showFeatureAddForm.isNew && (
                <FeatureForm
                    Report={getFeatureListFunction}
                    delFunction={deleteFeature}
                    onClose={handleCloseForm}
                    popClose={openDailogState}
                    drawerClose={openDrawer}
                />
            )}
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <>
                    {showFeatureAddForm.show && !showFeatureAddForm.isNew && (
                        <FeatureForm
                            Report={getFeatureListFunction}
                            delFunction={deleteFeature}
                            onClose={handleCloseForm}
                            data={featurerowData || []}
                            popClose={openDailogState}
                            drawerClose={openDrawer}
                        />
                    )}
                </>
            }
        </div>)
}