import React, { useState, useMemo, useEffect, useRef } from "react";
import { Button, Typography, ListItemButton, Avatar, Tooltip } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import AgentForm from "./agentForm";
import './agent.css'
import { getAgentbyId, getAgentListForAdmin, getgroups, updateAgentDetails, getSettingsList, importBulkAgents, getskillsDropdown, getAdminSettingsList } from "../../../services/api-service";
import Menu from "@mui/material/Menu";
import OZGridToolbar, { CustomImport } from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { BulkActionComponent } from "./BulkActionComponent";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import SimpleSwitch from "../../../components/admin/common/OZSwitch/SimpleSwitch";
import { stringAvatar } from "../../../components/admin/common/Avatar";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import AddAgentsToGroup from "./AddAgentsToGroup";
import OZDialog from "../../../components/admin/common/OZDialog";
import { checkRoles } from "../../../utils/util";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";


let agentStatus = null
const defaultCallControls = {
    hold: false,
    mute: false,
    endCall: false,
    answer: false,
    decline: false,
    manualDial: false,
    callRecording: false,
    transfer_agent_warm: false,
    transfer_agent_blind: false,
    transfer_skill_warm: false,
    transfer_skill_blind: false,
    transfer_phone_warm: false,
    transfer_phone_blind: false,
    transfer_ivr_warm: false,
    transfer_ivr_blind: false,
    conference_agent: false,
    conference_phone: false
}

function Agent() {

    const [openAgentForm, setOpenAgentForm] = useState({ isNew: false, show: false });
    const [onRowClickAgentTableData, setOnRowClickAgentTableData] = useState({});
    const [agentList, setAgentList] = useState([]);
    const [agentTableDataRef, setAgentTableDataRef] = useState(null)
    const [agentTableData, setAgentTableData] = useState([]);
    // const [filteredAgentTableData, setFilteredAgentTableData] = useState([]);
    const [groupData, setGroupData] = useState([])
    const [settingsData, setSettingsData] = useState([])
    const [skillData, setSkillData] = useState([])
    const [loading, setLoading] = useState(false)
    const [adminAgentsLength, setAdminAgentsLength] = useState(0)

    useEffect(() => {
        getAgents();
        getgroups();
        getSettingsData();
        getDropDown();
    }, [])

    async function getDropDown() {
        try {
            getskillsDropdown().then(resp => {
                if (resp.Status === "Success") {
                    setSkillData(resp.Data)
                }
                else {
                    showErrorNotification("Error accessing Skill Datas")
                }
            }).catch(e => {
                setSkillData([{ id: "0", skillName: "No Internet" }])
                showErrorNotification("No Skill Data Available, Check your Internet")
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setSkillData([])
        }
    };

    let groupsInitialData = {
        groups: null
    }

    const gridItemStyle = useMemo(
        () => ({
            fontWeight: 400,
            fontSize: "12px",
            color: "#000000"
        }),
        []
    );

    const ChangeColumnDefinitionsColor = (agentData) => {
        return (
            // <Tooltip title={(agentData?.data?.agentId) ? agentData?.data?.agentId : ''}>
            <Typography sx={{ ...gridItemStyle, color: "#3D8BF8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }} >{agentData.value}</Typography>
            // </Tooltip>
        );
    };

    function ChangeEmailColumnDefs(agentData) {
        return (
            <Typography sx={{
                ...gridItemStyle,
                color: agentData.data.active === false ? "#99A0A8" : "#000000", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
            }}> {agentData.value}</Typography >
        )
    }

    async function updateAgent(agentFormData, dontClose, index, length, deselectAllRows, mode) {
        setLoading(true);
        try {
            if (checkRoles('ROLE_CHAT_CALL') && agentFormData.blendedControls) {
                const blendedKeys = [
                    "blendedInbound",
                    "blendedManual",
                    "blendedPreview",
                    "blendedChat",
                    "blendedProgressive",
                    "inbound",
                    "manual",
                    "preview",
                    "progressive",
                    "chat",
                ];

                blendedKeys.forEach((key) => {
                    agentFormData[key] = false;
                });

                agentFormData.blendedControls && agentFormData.blendedControls.forEach((item) => {
                    if (blendedKeys.includes(item.key)) {
                        agentFormData[item.key] = true;
                    }
                });

                const hasBlendedKey = blendedKeys
                    .filter((key) => key.startsWith("blended"))
                    .some((key) => agentFormData[key]);

                if (hasBlendedKey) {
                    agentFormData.blended = true;
                } else {
                    agentFormData.blended = false;
                }
            }
            delete agentFormData.blendedControls;
            updateAgentDetails(agentFormData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        if (!(dontClose === true)) {
                            closeAgentForm();
                            setLoading(false);
                        }
                        if (length === "-1") {
                            getAgentbyId(agentFormData.id).then(resp => {
                                let newApiAgentData = structuredClone(resp?.Data[0])
                                newApiAgentData = handleBlendedKeys(newApiAgentData);
                                newApiAgentData = handleCallControlKeys(newApiAgentData);
                                newApiAgentData.agentData = newApiAgentData.agentData === "" ? null : newApiAgentData.agentData
                                if (newApiAgentData.skills.length > 0 && newApiAgentData.skills[0].priority !== null) {
                                    let newSkills = newApiAgentData.skills
                                    let skillListArray = {}
                                    newSkills.forEach(skills => {
                                        if (!skills.priority) return // exit (when no priority is entered)
                                        if (!skillListArray[skills.priority]) skillListArray[skills.priority] = [{ skillName: skills.skillName, id: skills.id }]
                                        else skillListArray[skills.priority].push({ skillName: skills.skillName, id: skills.id })
                                    })
                                    let newSkill = []
                                    Object.keys(skillListArray).forEach(item => {
                                        let skills = { 'priority': item, 'skillList': skillListArray[item] }
                                        newSkill.push(skills)
                                    })
                                    newApiAgentData.skills = newSkill
                                    setOnRowClickAgentTableData(newApiAgentData)
                                    setLoading(false);
                                }
                                else {
                                    newApiAgentData.skills = [{ 'priority': "1", 'skillList': [] }]
                                    setOnRowClickAgentTableData(newApiAgentData)
                                    setLoading(false);
                                }
                            }
                            )
                            deselectAllRows("View")
                            setLoading(false);
                        }
                        if (index !== undefined) {
                            if (index === length - 1) {
                                getAgents()
                                showSuccessNotification(`${agentFormData.agentId} is updated Successfully`)
                                deselectAllRows()
                            }
                            setLoading(false);
                        }
                        else {
                            getAgents();
                            showSuccessNotification(`${agentFormData.agentId} is updated Successfully`)
                            setLoading(false);
                        }
                    }
                    else {
                        showErrorNotification(resp?.Message?.agentData || resp?.Message);
                        setLoading(false);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    }

    const SwitchCellRenderer = (agentRowData) => {
        const [locked, setLocked] = useState(agentRowData.value);
        const [lockedconfirmationDialogState, setLockedConfirmationDialogState] = useState(false);

        const changeLockValue = (e) => {
            setLocked(e.target.checked)
            setLockedConfirmationDialogState(true);
        };

        const onCloseLocked = () => {
            setLockedConfirmationDialogState(false);
            setLocked(agentRowData.value);
        };

        return (
            <div className="oz-Agent-Grid-Locked-Container">
                {lockedconfirmationDialogState && <DeleteDialog mode="Add" title={"Locked State Confirmation"} content={`Are you Sure you want to change the Lock of Agent: ${agentRowData.data.agentName}?`} onClose={() => { onCloseLocked() }
                } open={lockedconfirmationDialogState} deleteFunction={() => { updateLocked(locked, agentRowData.data.id); }} />}
                {agentRowData?.data?.active === true &&
                    <SimpleSwitch name={agentRowData.value} checked={locked}
                        // onChange={(e) => authPage(AdminPages.AGENTS_EDIT) && changeLockValue(e)}
                        onChange={(e) => (newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && changeLockValue(e)}
                    />
                }
            </div>
        );

    }

    async function updateLocked(locked, id) {
        try {
            getAgentbyId(id)
                .then((resp) => {
                    let newApiAgentData = structuredClone(resp?.Data[0])
                    newApiAgentData = handleBlendedKeys(newApiAgentData);
                    newApiAgentData.agentData = newApiAgentData.agentData === "" ? null : newApiAgentData.agentData
                    setOnRowClickAgentTableData(newApiAgentData)
                    newApiAgentData.locked = locked
                    updateAgent(newApiAgentData)
                })
        }
        catch (e) {
            showErrorNotification("Error in users list in update");
        }
    };

    function AgentGroupNamesFormatter(agentData) {
        const agentGroupNames = agentData?.data?.GroupName.split(',');

        let agentGroupName;
        if (agentGroupNames.length > 2) {
            const agentGroupNamesLastTwo = agentGroupNames.slice(-2).join(',');
            const remainingCount = agentGroupNames.length - 2;
            const remainingAgentGroupNames = agentGroupNames.slice(0, -2).join(',');
            agentGroupName = (
                <>
                    <div>
                        {agentGroupNamesLastTwo}
                        <span key="badge" className="badge" title={remainingAgentGroupNames}>
                            {`+${remainingCount}`}
                        </span>
                    </div>
                </>
            )
        }
        else {
            agentGroupName = agentGroupNames.join(',');
        }
        return (<Typography style={{ ...gridItemStyle }}>{agentGroupName}</Typography>)
    }


    function EditAgentRowOnMouseEnter(agentRowData) {
        const [agentAnchorE1, setAgentAnchorE1] = useState(false);
        const anchorRef = useRef();
        const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
        const [openAddtoGroup, setOpenAddtoGroup] = useState(false)

        const ClickToOpenMenu = () => {
            setAgentAnchorE1(null)
            setOpenAddtoGroup(true)
        };

        const handleCloseGroups = () => {
            setOpenAddtoGroup(false)
        };

        const handleCloseInUpdate = () => {
            setOpenAddtoGroup(false)
            setAgentAnchorE1(null)
        }

        const handleActiveChangeStatus = (e) => {
            if (hideAddButton()) {
                agentRowData.data.active = e;
                agentStatus = e
                setOpenDeleteDialog(true)
            }
            else {
                showErrorNotification("Agent cannot be activated. Limit exceeded")
            }

        };
        const handleDeActivateChangeStatus = (e) => {
            agentRowData.data.active = e;
            agentStatus = e
            setOpenDeleteDialog(true)
        };

        function updateAgentStatus() {
            getAgentbyId(agentRowData.data.id).then(resp => {
                let newApiAgentData = structuredClone(resp?.Data[0]);
                newApiAgentData = handleBlendedKeys(newApiAgentData);
                newApiAgentData.agentData = newApiAgentData.agentData === "" ? null : newApiAgentData.agentData
                setOnRowClickAgentTableData(newApiAgentData)
                newApiAgentData.active = agentStatus
                updateAgent(newApiAgentData);
            })
        }

        function handleCloseDelete() {
            agentRowData.data.active = !agentRowData.data.active
            setOpenDeleteDialog(false)
        }

        const onClickMoreVertIcon = () => {
            setTimeout(() => setAgentAnchorE1(anchorRef?.current), 1);
        };

        const handleCloseMorevertMenu = () => {
            setAgentAnchorE1(null);
        };

        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '16px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        return (
            <div className="oz-Agent-MoreVert-Container">
                <Typography sx={{ ...gridItemStyle, color: agentRowData.value ? "#000000" : "#99A0A8" }}> {agentRowData.value ? "Active" : "Deactivated"}</Typography>
                {/* {authPage(AdminPages.AGENTS_EDIT) && <img className="agentMorevertIcon" onClick={onClickMoreVertIcon} src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"></img>}                <div ref={anchorRef}> */}
                {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <img className="agentMorevertIcon" onClick={onClickMoreVertIcon} src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"></img>}                <div ref={anchorRef}>
                    <Menu anchorEl={agentAnchorE1} open={Boolean(agentAnchorE1)} onClose={handleCloseMorevertMenu} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "133px",
                            height: "62px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-0.3625rem",
                            marginTop: "0rem",
                        },
                    }}>
                        <ListItemButton
                            sx={{ ...styleListItemButton, marginTop: "1px" }}
                            onClick={() => { ClickToOpenMenu() }}
                            disabled={newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit')}
                        >
                            Add to group
                        </ListItemButton>
                        <ListItemButton
                            sx={{ ...styleListItemButton, marginTop: '9px' }}
                            disabled={newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit')}
                            onClick={() => { agentRowData?.data?.active === true ? handleDeActivateChangeStatus(!agentRowData?.value) : handleActiveChangeStatus(!agentRowData?.value) }}

                        >
                            {agentRowData.value ? "Deactivate" : "Activate"}
                        </ListItemButton>
                    </Menu>
                    {openDeleteDialog && <DeleteDialog mode="Add" title={"Update Agent Status"} content={"Do you want to change the status of the Selected Agent?"} onClose={() => { handleCloseDelete() }
                    } open={openDeleteDialog} deleteFunction={() => { updateAgentStatus(); setOpenDeleteDialog(false) }} />}
                    {openAddtoGroup &&
                        <OZDialog
                            open={openAddtoGroup}
                            title='Add Agent to Group'
                            onClose={() => { handleCloseGroups(); }}
                            hideActions={true}
                        >
                            <AddAgentsToGroup initialData={groupsInitialData} autoCompleteList={groupData} handleClose={handleCloseGroups} handleCloseInUpdate={handleCloseInUpdate} list={[agentRowData.data]} />
                        </OZDialog>
                    }
                </div>
            </div>
        );
    };

    useEffect(() => {
        getGroupsDropDown();
    }, []);

    async function getGroupsDropDown() {
        try {
            getgroups().then(resp => {
                setGroupData(resp.Data);
            }).catch(e => {
                setGroupData([{ id: "0", skillName: "No Internet" }])
                setGroupData("No Skill Data Available, Check your Internet")
            })
        }
        catch (e) {
            setGroupData(JSON.stringify(e));
            setGroupData([])
        }
    };

    const AgentNameColumnCellRenderer = (columnData) => {
        return (
            <div className="oz-Admin-Avatar-Container">
                <Avatar style={{
                    width: 20,
                    height: 20,
                    fontWeight: 400,
                    color: "#212121",
                    fontSize: "9px"
                }}
                    variant="circular"  {...stringAvatar(columnData.value)} ></Avatar>
                <Tooltip title={(columnData?.data?.agentName) ? columnData?.data?.agentName : ''}>
                    <Typography sx={{ ...gridItemStyle, color: columnData.data.active === false ? "#99A0A8" : "#000000", paddingLeft: "5px", maxWidth: "260px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }}>{columnData.value}</Typography>
                </Tooltip>
            </div >)
    };


    function SkillsAddedCellRenderer(agentData) {

        const gridItemStyle = useMemo(
            () => ({
                fontWeight: 400,
                fontSize: "12px",
                color: "#000000"
            }),
            []
        );

        const agentNames = agentData?.data?.skills.split(',');

        let agentName;
        if (agentNames.length > 2) {
            const agentNamesLastTwo = agentNames.slice(-2).join(', ');
            const remainingCount = agentNames.length - 2;
            const remainingAgentNames = agentNames.slice(0, -2).join(', ');
            agentName = (
                <>
                    <div>
                        {agentNamesLastTwo}
                        <span key="badge" className="badge" title={remainingAgentNames}>
                            {`+${remainingCount}`}
                        </span>
                    </div>
                </>
            )
        }
        else {
            agentName = agentNames.join(', ');
        }
        return (<Typography style={{ ...gridItemStyle }}>{agentName}</Typography>)
    }


    function SkillsCountCellRenderer(skillData) {

        const gridItemStyle = useMemo(
            () => ({
                fontWeight: 400,
                fontSize: "12px",
                color: "#000000",
                marginLeft: "30px"
            }),
            []
        );
        return (<Typography style={{ ...gridItemStyle }}>{skillData.value}</Typography>)
    }

    const columnDefs = [
        {
            field: 'locked', headerName: 'Locked', checkboxSelection: true,
            showDisabledCheckboxes: true,
            headerCheckboxSelection: true,
            maxWidth: 135,
            cellRenderer: SwitchCellRenderer
        },
        {
            field: 'agentId', headerName: 'Agent ID',
            // maxWidth: 180,
            cellRenderer: ChangeColumnDefinitionsColor,
        },
        {
            field: 'agentName',
            maxWidth: 300,
            headerName: 'Full Name',
            cellRenderer: AgentNameColumnCellRenderer,
        },
        {
            field: 'email', headerName: 'Email',
            maxWidth: 300,
            cellRenderer: ChangeEmailColumnDefs,
        },
        {
            field: 'GroupName', headerName: 'Groups',
            maxWidth: 400,
            cellRenderer: AgentGroupNamesFormatter,
        },
        { field: 'skills', headerName: "Skills Assigned", cellRenderer: SkillsAddedCellRenderer, minWidth: 270, maxWidth: 400, },
        { field: 'skillsCount', headerName: "Skills Count", cellRenderer: SkillsCountCellRenderer },
        {
            field: 'active', headerName: "Status", maxWidth: 10000,
            cellRenderer: EditAgentRowOnMouseEnter,
        },
    ]

    async function getAgents() {
        setLoading(true);
        try {
            getAgentListForAdmin().then(resp => {
                if (resp.Data) {
                    const updatedItems = resp?.Data.map(item => {
                        const skillsList = item.skills ? item.skills?.split(',') : [];
                        return {
                            ...item,
                            skillsCount: skillsList.length
                        };
                    });
                    setAgentTableData(updatedItems);
                }
                let length = resp?.AdminAgentsLength || 0;
                length = typeof length === 'string' ? Number(length) : length;
                setAdminAgentsLength(length);
                // setFilteredAgentTableData(resp.Data.filter(item => item.active === true))
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setAgentTableData([])
                setAdminAgentsLength(0)
                console.log(e)
            })
        }
        catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    const addAgentButtonStyle = useMemo(
        () => ({
            width: "136px",
            height: '30px',
            borderRadius: "0.5rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    const agentHeader = useMemo(
        () => ({
            fontWeight: 600, fontSize: "1.5rem", lineHeight: "1.75rem",
        }),
        []
    );
    const showAgentForm = () => {
        setOpenAgentForm({ isNew: true, show: true });
    }

    const closeAgentForm = () => {
        setOpenAgentForm(false)
        setOpenAgentForm({ isNew: false, show: false })
        setOnRowClickAgentTableData([]);
    }

    function handleCallControlKeys(newApiAgentData) {
        newApiAgentData.agentData = newApiAgentData.agentData === "" ? null : newApiAgentData.agentData
        try {
            newApiAgentData.callControls = JSON.parse(newApiAgentData.callControls);
            newApiAgentData.callControls.endCall = newApiAgentData.callControls.endcall;
            delete newApiAgentData.callControls.endcall;
            newApiAgentData.callControls.manualDial = newApiAgentData.callControls.manualdial;
            delete newApiAgentData.callControls.manualdial;
            newApiAgentData.callControls.callRecording = newApiAgentData.callControls.callrecording;
            delete newApiAgentData.callControls.callrecording;
        }
        catch (e) {
            console.log("Error in parsing callControls so setting default values", e)
            newApiAgentData.callControls = defaultCallControls
        }
        if (newApiAgentData.callControls) {
            newApiAgentData.callControls.transfer = {
                agent: {
                    warm: newApiAgentData.callControls.transfer_agent_warm,
                    blind: newApiAgentData.callControls.transfer_agent_blind
                },
                skill: {
                    warm: newApiAgentData.callControls.transfer_skill_warm,
                    blind: newApiAgentData.callControls.transfer_skill_blind
                },
                phone: {
                    warm: newApiAgentData.callControls.transfer_phone_warm,
                    blind: newApiAgentData.callControls.transfer_phone_blind
                },
                ivr: {
                    warm: newApiAgentData.callControls.transfer_ivr_warm,
                    blind: newApiAgentData.callControls.transfer_ivr_blind
                }
            }
            newApiAgentData.callControls.conference = {
                agent: newApiAgentData.callControls.conference_agent,
                phone: newApiAgentData.callControls.conference_phone
            }
            Object.keys(newApiAgentData.callControls).forEach(key => {
                if (key.includes("transfer_") || key.includes("conference_")) {
                    delete newApiAgentData.callControls[key]
                }
            })
        }
        console.log("newApiAgentData:", newApiAgentData)
        return newApiAgentData;
    }

    function handleBlendedKeys(newApiAgentData) {
        if (checkRoles('ROLE_CHAT_CALL')) {
            const initialSelectedOptions = [];

            const mainOptions = [
                { label: "Inbound Call", key: "inbound" },
                { label: "Manual Dial", key: "manual" },
                { label: "Preview Dial", key: "preview" },
                { label: "Progressive Dial", key: "progressive" },
                { label: "Digital", key: "chat" },
                { label: "Blended", key: "blendedNormal", hasSubOptions: true },
            ];

            const blendedSubOptions = [
                { label: "Inbound", key: "blendedInbound" },
                { label: "Manual", key: "blendedManual" },
                { label: "Preview", key: "blendedPreview" },
                { label: "Progressive", key: "blendedProgressive" },
                { label: "Digital", key: "blendedChat" },
            ];

            mainOptions.forEach((option) => {
                // if (formikTemp[option.key] && option.key !== 'blended') {
                if (newApiAgentData[option.key] && option.key !== 'blendedNormal') {
                    initialSelectedOptions.push(option);
                }
            });

            blendedSubOptions.forEach((subOption) => {
                if (newApiAgentData[subOption.key]) {
                    initialSelectedOptions.push(subOption);
                }
            });
            newApiAgentData.blendedControls = initialSelectedOptions
        }
        return newApiAgentData;
    }

    function onClickRow(rowItem) {
        if (rowItem?.data?.active === false) {
            showErrorNotification(`${rowItem?.data?.agentName} is Deactivated, Kindly Activate the Agent first`);
            return null;
        } else {
            setOnRowClickAgentTableData({});
            setLoading(true);
            try {
                getAgentbyId(rowItem?.data?.id).then(resp => {
                    let newApiAgentData = structuredClone(resp?.Data[0])
                    newApiAgentData = handleBlendedKeys(newApiAgentData);
                    newApiAgentData = handleCallControlKeys(newApiAgentData);
                    if (newApiAgentData?.skills?.length > 0 && newApiAgentData?.skills[0]?.priority !== null) {
                        let newSkills = newApiAgentData.skills
                        let skillListArray = {}
                        newSkills.forEach(skills => {
                            if (!skills.priority) return // exit (when no priority is entered)
                            if (!skillListArray[skills.priority]) skillListArray[skills.priority] = [{ skillName: skills.skillName, id: skills.id }]
                            else skillListArray[skills.priority].push({ skillName: skills.skillName, id: skills.id })
                        })
                        let newSkill = []
                        Object.keys(skillListArray).forEach(item => {
                            let skills = { 'priority': item, 'skillList': skillListArray[item] }
                            newSkill.push(skills)
                        })
                        newApiAgentData.skills = newSkill
                        setOnRowClickAgentTableData(newApiAgentData)
                        setLoading(false);
                    }
                    else {
                        newApiAgentData.skills = [{ 'priority': "1", 'skillList': [] }]
                        setOnRowClickAgentTableData(newApiAgentData)
                        setLoading(false);
                    }
                    setOpenAgentForm({ isNew: false, show: true });
                }).catch((e) => {
                    console.log(e);
                    setOnRowClickAgentTableData({});
                    setLoading(false);
                    showErrorNotification("Error in fetching Agent Data");
                })
            } catch (e) {
                showErrorNotification("Error in Agent list ");
                setLoading(false);
            }
        }
    }

    function addSelectedRowData(selectedData) {
        setAgentList(selectedData);
    }

    const mainAgentHeader = useMemo(
        () => ({
            fontSize: "0.75rem", lineHeight: "0.875rem", marginBottom: "0.625rem", color: "#212121"
        }),
        []
    );

    async function getSettingsData() {
        try {
            getAdminSettingsList().then(resp => {
                setSettingsData(resp.Data);
            }).catch(e => {
                setSettingsData({})
                console.log(e)
            })
        }
        catch (e) {
            console.log(e)
        }
    }

    function hideAddButton() {
        let agentCount = null;
        console.log(settingsData)
        if (settingsData.length > 0) {
            settingsData?.forEach((item) => {
                if (item?.ParameterCode === "AGENT_CREATION_LIMIT") {
                    if (item?.ParameterValue === null) {
                        agentCount = item?.DefaultValue;
                    }
                    else {
                        agentCount = item?.ParameterValue
                    }
                }
            })
            console.log(agentCount)
            console.log(adminAgentsLength, Number(agentCount))
            if (agentCount === "-1") {
                return true;
            }
            else if (adminAgentsLength < Number(agentCount)) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    function onClickSampleDownload() {
        const link = document.createElement("a");
        link.download = `agents_upload_sample.xls`;
        link.href = `${process.env.PUBLIC_URL}/sample_files/agents_upload_sample.xls`;
        link.click();
    };

    return (
        <div>
            {((!openAgentForm.show && !openAgentForm.isNew) || (openAgentForm.show && !openAgentForm.isNew) || (!openAgentForm.show && openAgentForm.isNew)) &&
                <div className="ozAdminAgentContainer">
                    <Typography sx={mainAgentHeader}>
                        People
                    </Typography>
                    <div className="oz-Agent-InnerDivGroup">
                        <Typography sx={agentHeader} alignSelf='center' flexGrow={1}>
                            Agents
                        </Typography>

                        {/* {authPage(AdminPages.AGENTS_EDIT) && hideAddButton() && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_AGENTBULKUPLOAD")) && */}
                        {(newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'data') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full') || newAuthPages(NewAdminPages.AGENTS_PAGE)) && hideAddButton() && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_AGENTBULKUPLOAD")) &&
                            <CustomImport
                                childImportProps={
                                    {
                                        importProps: {
                                            fileName: "Agents.xls",
                                            importFunction: importBulkAgents,
                                            label: "Bulk Import Agents",
                                            onClickSampleDownload: onClickSampleDownload
                                        },
                                        refreshGrid: getAgents,
                                        marginLeft: "-12rem"
                                    }}
                            />
                        }
                        {/* {(hideAddButton() && authPage(AdminPages.AGENTS_EDIT)) && <Button variant="contained" sx={addAgentButtonStyle} onClick={showAgentForm} startIcon={<AddBoxOutlinedIcon />}> */}
                        {(hideAddButton() && (newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full') || newAuthPages(NewAdminPages.AGENTS_PAGE))) && <Button variant="contained" sx={addAgentButtonStyle} onClick={showAgentForm} startIcon={<AddBoxOutlinedIcon />}>
                            Add Agent
                        </Button>}
                    </div>
                    <BulkActionComponent initialData={groupsInitialData} autoCompleteList={groupData} list={agentList} updateFuntion={updateAgent} />
                    <OZGridToolbar title='List of Agents' searchPlaceholder='Agent ID' rowCount={agentTableData.length} dataGridRef={agentTableDataRef} showFilters={true} filtertProps={{ column: 'active', options: [{ Active: true }, { Deactivated: false }] }} fileName='agentList' />
                    {loading ?
                        (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                <LoadingScreen />
                            </div>
                        ) :
                        (
                            // <OZDataGrid height="26.3rem" setGridRef={setAgentTableDataRef} columns={columnDefs} data={agentTableData} rowClickCallback={onClickRow} onRowSelected={authPage(AdminPages.AGENTS_EDIT) && addSelectedRowData} />
                            <OZDataGrid height="26.3rem" setGridRef={setAgentTableDataRef} columns={columnDefs} data={agentTableData} rowClickCallback={onClickRow} onRowSelected={newAuthPages(NewAdminPages.AGENTS_PAGE || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'View')) && addSelectedRowData} />
                        )
                    }
                </div >}
            {
                (openAgentForm.show && openAgentForm.isNew) && (
                    <div className="oz-Agent-Add-Form-Container">
                        <Typography sx={{
                            ...mainAgentHeader, cursor: "pointer"
                        }} onClick={closeAgentForm}>
                            Agent / Add Agent
                        </Typography>
                        <Typography sx={agentHeader}>
                            Add Agent
                        </Typography>
                        <AgentForm onClose={closeAgentForm} refreshAgentGrid={getAgents} skillData={skillData} />
                    </div>
                )
            }
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (
                    <>
                        {(openAgentForm.show && !openAgentForm.isNew) && <AgentForm updateAgent={updateAgent} refreshAgentGrid={getAgents} hideAgent={hideAddButton} onClose={closeAgentForm} data={onRowClickAgentTableData} skillData={skillData} />}
                    </>
                )
            }
        </div >
    )
}

export default Agent;