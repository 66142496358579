import React, { useEffect, useMemo, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { connect, getIn } from "formik";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Popper from "@mui/material/Popper";
import Avatar from '@mui/material/Avatar';
import "../../../../pages/admin/admin.css";
import { Chip, FormHelperText, FormControl, Box, Typography, Tooltip } from "@mui/material";
import CustomizedCheckbox from "../CustomCheckBox";
import { stringAvatar } from "../Avatar";

const Autocompletes = connect(
  ({
    Datas,
    PlaceHolder,
    formik,
    name,
    optionLabelName,
    optionId,
    mode,
    ...additionalprops
  }) => {

    const listRef = useRef(null);
    const [limit, setLimit] = useState(10);
    const [inputValue, setInputValue] = useState("");

    const handleScroll = () => {
      setLimit(prev => prev + 5);
    };



    // Don't reset limit on close
    const handleClose = () => { };

    const PopperMy = useMemo(
      () => (props) => {
        return (
          <Popper {...props} sx={{
            paddingTop: "8px", paddingBottom: "8px", color: "#212121",
            "& .MuiAutocomplete-listbox": {
              "& li": {
                paddingLeft: mode === "Edit" ? "0px" : "8px",
                maxWidth: "100% !important",
                wordBreak: "break-all !important",
                whiteSpace: "normal !important",
                textOverflow: "inherit !important"
              },
              "& .MuiAutocomplete-option[aria-selected='true']": {
                backgroundColor: "transparent !important",
              },
            },
            '& .MuiAutocomplete-paper': {
              boxShadow: 'none',
              border: "1px solid #E5E5E5",
              borderRadius: mode === "Edit" ? '0px' : "8px",
              borderBottom: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderRight: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderLeft: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderTop: mode === "Edit" ? "0px" : "1px solid #E5E5E5",
              height: mode === "Edit" ? "290px" : "",
            }, "& .MuiAutocomplete-noOptions": {
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#212121"
            }
          }} />
        );
      },
      [],
    );

    const selected = getIn(formik?.values, name);
    // const selectAll = selected?.length === Datas?.length;
    let [selectAll, setSelectAll] = useState(selected?.length === Datas?.length);
    const error = getIn(formik.errors, name);

    const getTouchedStatus = () => {
      let touched = getIn(formik.touched, name);
      if (touched) {
        return touched.length === 0
      } else {
        return touched
      }
    }

    useEffect(() => {
      if (selected?.length === 0) {
        setSelectAll(false);
      }
    }, [selected]);

    const touch = getTouchedStatus();

    // Very simplified filter logic - this should avoid performance issues
    const simplifiedFilter = createFilterOptions({
      matchFrom: 'any',
      limit: limit,
      ignoreCase: true,
    });

    return (
      <>
        {mode === "Edit" &&
          <Box sx={{
            border: 1,
            borderRadius: "8px",
            paddingTop: "8px",
            paddingLeft: "15px",
            paddingBottom: "8px",
            borderColor: "#D6D6D7",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginTop: "-30px",
          }}
          >
            <label
              sx={{
                color: "#212121",
                fontWeight: 400,
                fontSize: "14px",
                marginLeft: "10px",
              }}
            >
              {selected?.length} Selected...
            </label>
          </Box>
        }
        <Box
          sx={(mode === "Edit") ? {
            border: 1,
            borderRadius: "8px",
            paddingTop: "15px",
            paddingLeft: "15px",
            paddingBottom: "305px",
            borderColor: "#D6D6D7",
            borderTop: 0,
            paddingRight: "15px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          } : {}}
        >
          <Autocomplete
            sx={{
              "& .css-5dy5t1-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: "8px",
                minHeight: "44px",
                fontSize: "14px",
                borderColor: "#E6E5E6"
              },
              '& .MuiAutocomplete-listbox': {
                scrollbarColor: 'rgb(190, 190, 190) rgb(240, 240, 240)',
                scrollbarWidth: 'thin',
                borderRadius: "8px"
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: "8px",
                minHeight: "44px",
                fontSize: "14px",
                borderColor: "#E6E5E6"
              }
            }}
            className="ozcw-scrollbar"
            noOptionsText={"No matching result found!"}
            multiple={true}
            size="small"
            id={name}
            options={Datas}
            value={selected}
            inputValue={inputValue}
            selectOnFocus={false}
            disableCloseOnSelect={true}
            autoComplete={true}

            renderTags={(value) => (
              <div style={{
                display: "flex",
                alignItems: 'center',
                alignContent: "center",
              }}>
                <Typography component={'div'}>
                  {selected?.map(
                    (selectedValues, index) =>
                    (index < 3 && (
                      <Chip
                        key={`${selectedValues[optionLabelName]}-${index}`}
                        label={selectedValues[optionLabelName]}
                        size="small"
                        className="oz-customAutoComplete-chip-style"
                      />
                    ))
                  )}
                </Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#212121",
                  paddingRight: '5px',
                  paddingLeft: "5px"
                }}>
                  {(selected?.length > 3) ? "..." : ""}
                </Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#99A0A8",
                }}>
                  {(selected?.length > 3) ? (("+" + (selected?.length - 3))) : ""}
                </Typography>
              </div>
            )}

            onChange={(e, value) => {
              if (value.find((option) => option.id === 0)) {
                // Handle "Select All" action
                const filteredOptions = Datas.filter(option =>
                  inputValue ? option[optionLabelName].toLowerCase().includes(inputValue.toLowerCase()) : true
                );

                if (selected?.length === filteredOptions.length) {
                  formik.setFieldValue(name, []);
                  setSelectAll(false);
                } else {
                  formik.setFieldValue(name, filteredOptions);
                  setSelectAll(true);
                }
              } else {
                formik.setFieldValue(name, value);
              }
            }}

            getOptionLabel={option => option[optionLabelName]}
            PopperComponent={PopperMy}

            isOptionEqualToValue={(option, value) =>
              option[optionId || optionLabelName] === value[optionId || optionLabelName]
            }

            ListboxProps={{
              ref: listRef,
              onScroll: handleScroll,
              sx: { maxHeight: "280px" }
            }}

            onClose={handleClose}

            onInputChange={(e, newValue, reason) => {
              if (reason === 'input') {
                setInputValue(newValue);
              }
            }}


            filterOptions={(options, params) => {
              // Basic filtering
              let filtered = simplifiedFilter(options, params);

              // Add "Select All" if we have results
              if (filtered.length > 0 && !params.inputValue.includes("Select All")) {
                filtered = [{ id: 0, [optionLabelName]: "Select All" }, ...filtered];
              }

              return filtered;
            }}

            renderOption={(props, option, { selected }) => (
              <li {...props} key={`${option[optionLabelName]}-${option.id}`}>
                <div style={{ display: 'flex', alignItems: "center" }}>
                  <CustomizedCheckbox
                    checked={(option.id === 0) ? selectAll : selected}
                  />
                  {option.id !== 0 && (
                    <Avatar
                      style={{
                        width: "24px",
                        height: "24px",
                        fontSize: "14px",
                        color: "#212121",
                        marginLeft: "4px"
                      }}
                      {...stringAvatar(option[optionLabelName])}
                    />
                  )}
                  <span style={{
                    marginLeft: "8px",
                    color: "#212121",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16.41px"
                  }}>
                    {option[optionLabelName]}
                  </span>
                </div>
              </li>
            )}

            renderInput={(params) => (
              <div>
                <FormControl
                  component="fieldset"
                  error={touch & Boolean(error) ? true : false}
                  fullWidth
                >
                  <TextField
                    error={touch & Boolean(error) ? true : false}
                    {...params}
                    placeholder={PlaceHolder}
                  />
                  <FormHelperText>
                    {touch & Boolean(error) ? error : null}
                  </FormHelperText>
                </FormControl>
              </div>
            )}
          />
        </Box>
      </>
    );
  }
);

export default Autocompletes;